@if (group) {
  <mat-list class="dashboard-list-container" dense>
    @if (group.type !== GROUP_TYPE.EMPTY) {
      <mat-list-item class="isa-sidenav-group" matRippleDisabled>
        <i
          matListItemIcon
          class="fas"
          [ngClass]="{
            'fa-gavel': group.type === GROUP_TYPE.JUDGING,
            'fa-edit': group.type === GROUP_TYPE.REPORTING,
            'fa-chart-bar':
              group.type === GROUP_TYPE.OVERVIEW || group.type === GROUP_TYPE.SITUATIONAL_AWARENESS,
            'fa-trophy': group.type === GROUP_TYPE.SCORING,
            'fa-flag': group.type === GROUP_TYPE.TASKS
          }"
          aria-hidden="true"
        ></i>
        <span matListItemLine>{{ group.type | isaReplaceUnderScores }}</span>
      </mat-list-item>
    }
    @if (!isDefaultExerciseLoading) {
      <mat-nav-list>
        @for (link of group.links; track link) {
          <a
            mat-list-item
            [routerLink]="link.uri"
            routerLinkActive="router-link-active"
            class="dashboard-list-item"
          >
            <i matListItemIcon></i>
            <span
              matListItemLine
              [class]="{ beta: link.prerequisites?.fromExperimentalFeatures === true }"
              >{{ 'ui.nav.' + getLinkName(link) | translate }}</span
            >
          </a>
        }
      </mat-nav-list>
    }
    @if (isDefaultExerciseLoading) {
      <cybexer-progress-spinner></cybexer-progress-spinner>
    }
  </mat-list>
}
