import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationsService } from '@cybexer/ngx-commons';
import {
  Exercise,
  ReportAIAssessment,
  SituationReportConfirmation,
  SituationReportsData,
} from '../../../../models';
import { AuthenticationService, SituationReportsService } from '../../../../services';
import {
  ASSESSMENT_STATUS,
  CONFIRMATION_STATUS,
  CustomValidators,
  EXERCISE_PERMISSIONS,
  FormUtil,
  ROLES,
} from '../../../../shared';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'isa-situation-report-confirm-dialog',
  templateUrl: './situation-report-confirm-dialog.component.html',
  styleUrls: ['./situation-report-confirm-dialog.component.scss'],
})
export class SituationReportConfirmDialogComponent implements OnInit {
  submitted: boolean;
  situationReportsData: SituationReportsData;
  reportDetails?: string;
  reportDetailsLoaded = false;
  exercise: Exercise;
  aiFeaturesEnabled: boolean = false;
  promptOverrideId?: string;
  blueStatusReportForm: UntypedFormGroup;
  CONFIRMATION_STATUS = CONFIRMATION_STATUS;
  hasPermissionToConfirmOrDeny = false;

  protected readonly ASSESSMENT_STATUS = ASSESSMENT_STATUS;
  protected readonly EXERCISE_PERMISSIONS = EXERCISE_PERMISSIONS;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SituationReportConfirmDialogInput,
    private dialogRef: MatDialogRef<SituationReportConfirmDialogComponent>,
    private situationReportsService: SituationReportsService,
    private notificationsService: NotificationsService,
    private customValidators: CustomValidators,
    private authenticationService: AuthenticationService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.situationReportsData = this.data.situationReportsData;
    this.exercise = this.data.exercise;
    this.aiFeaturesEnabled = this.data.aiFeaturesEnabled;
    this.promptOverrideId = this.data.promptOverrideId;
    this.situationReportsService
      .getReportDetails(
        this.exercise.id,
        this.situationReportsData.teamId,
        this.situationReportsData.reportId
      )
      .pipe(untilDestroyed(this))
      .subscribe((reportDetails) => {
        this.reportDetails = reportDetails.data;
        this.reportDetailsLoaded = true;
      });
    this.authenticationService
      .hasGamenetPermission([ROLES.ADMIN, ROLES.WHITE], true, false)
      .pipe(untilDestroyed(this))
      .subscribe((x: boolean) => {
        return (this.hasPermissionToConfirmOrDeny = x);
      });

    this.blueStatusReportForm = new UntypedFormGroup({
      grade: new UntypedFormControl(
        {
          value:
            this.situationReportsData.grade ||
            (this.aiFeaturesEnabled ? this.situationReportsData.aiGrade : undefined),
          disabled: this.situationReportsData.status !== CONFIRMATION_STATUS.PENDING_CONFIRMATION,
        },
        [Validators.required, this.customValidators.integer({ min: 0, max: 10 })]
      ),
      feedback: new UntypedFormControl({
        value:
          this.situationReportsData.feedback ||
          (this.aiFeaturesEnabled ? this.situationReportsData.aiFeedback : undefined),
        disabled:
          !this.hasPermissionToConfirmOrDeny ||
          this.situationReportsData.status !== CONFIRMATION_STATUS.PENDING_CONFIRMATION,
      }),
    });
  }

  submitConfirmation(): void {
    this.submitted = true;
    if (this.blueStatusReportForm.valid) {
      const situationReportConfirmation: SituationReportConfirmation =
        new SituationReportConfirmation({
          reportId: this.situationReportsData.reportId,
          exerciseId: this.exercise.id,
          grade: +this.blueStatusReportForm.value.grade,
          feedback: SituationReportConfirmDialogComponent.formatFeedback(
            this.blueStatusReportForm.value.feedback
          ),
        });
      this.situationReportsService
        .createSituationReportConfirmation(situationReportConfirmation)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.notificationsService.success(
            this.translate.instant('ui.situationReport.reportConfirmed')
          );
          this.dialogRef.close(situationReportConfirmation);
        });
    }
  }

  isNumber(event): boolean {
    return event.keyCode === 8 || event.key.match(/[0-9\ ]/g) != null; // 8 is Backspace
  }

  onPaste(event): void {
    event.preventDefault();
  }

  showFeedback(): boolean {
    return (
      (this.hasPermissionToConfirmOrDeny &&
        this.situationReportsData.status === CONFIRMATION_STATUS.PENDING_CONFIRMATION) ||
      (this.situationReportsData.status !== CONFIRMATION_STATUS.PENDING_CONFIRMATION &&
        this.blueStatusReportForm.controls['feedback'].value)
    );
  }

  sendToAIAssessment() {
    if (this.situationReportsData.status !== CONFIRMATION_STATUS.PENDING_CONFIRMATION) return;

    const assessments = [
      new ReportAIAssessment({
        reportId: this.situationReportsData.reportId,
        teamId: this.situationReportsData.teamId,
        promptOverrideId: this.promptOverrideId,
      }),
    ];
    this.situationReportsService
      .sendToAIAssessment(this.exercise.id, assessments)
      .pipe(first())
      .subscribe(() => {
        this.notificationsService.success(this.translate.instant('ui.situationReport.reportSent'));
        this.dialogRef.close();
      });
  }

  private static formatFeedback(feedback?: string): string {
    if (feedback != null && FormUtil.getStringCharacterCount(feedback.trim()) > 0) {
      return FormUtil.replaceNewlines(feedback, 5, 4);
    }
    return '';
  }
}

export type SituationReportConfirmDialogInput = {
  situationReportsData: SituationReportsData;
  exercise: Exercise;
  aiFeaturesEnabled: boolean;
  promptOverrideId?: string;
};
