<div class="network-segment-dialog">
  <h2 matDialogTitle>Edit network segment</h2>
  <form [formGroup]="segmentFormGroup" (ngSubmit)="onSubmit(segmentFormGroup)">
    <mat-dialog-content class="network-segment-dialog-content">
      <div class="isa-mat-form-row">
        <mat-form-field class="name-field full-width">
          <mat-label>Network segment name</mat-label>
          <input autocomplete="off" matInput required="true" type="text" formControlName="name" />
        </mat-form-field>
        <div class="isa-mat-form-row">
          <span class="color-input-label">Color</span>
          <input
            autocomplete="off"
            matInput
            type="color"
            formControlName="color"
            class="color-input"
          />
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <div>
        <button type="submit" class="isa-button-confirm" mat-raised-button color="primary">
          Update
        </button>
        <button mat-button matDialogClose>Cancel</button>
      </div>
    </mat-dialog-actions>
  </form>
</div>
