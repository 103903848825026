import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from '@cybexer/ngx-commons';
import { BehaviorSubject, mergeMap, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { User } from '../../models';
import {
  DomainPermission,
  getExercisePermissionFromRole,
  PermissionConfiguration,
  RoleCode,
  ROLES,
} from '../../shared';
import { ExerciseService } from '../gamenet/exercise.service';

@Injectable()
export class AuthenticationService {
  static OBSERVER_ID_KEY = 'observer';
  currentUser: User;
  currentUser$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  constructor(
    private http: HttpClient,
    private router: Router,
    private exerciseService: ExerciseService,
    private notificationsService: NotificationsService
  ) {}

  login(username: string, password: string): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Basic ' + AuthenticationService.encodeBase64(username + ':' + password),
      }),
    };
    return this._login(httpOptions);
  }

  loginAsObserver(): Observable<User> {
    let observerId = localStorage.getItem(AuthenticationService.OBSERVER_ID_KEY);
    if (!observerId) {
      observerId = `observer${new Date().getTime()}`;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Observer ' + AuthenticationService.encodeBase64(observerId),
      }),
    };
    return this._login(httpOptions).pipe(
      tap(() => localStorage.setItem(AuthenticationService.OBSERVER_ID_KEY, observerId))
    );
  }

  loginWithAccessToken(token: string): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `AccessToken ${token}`,
      }),
    };
    return this._login(httpOptions);
  }

  private _login(httpOptions: any): Observable<User> {
    this.currentUser = null;
    return this.http.post<User>('api/auth/login', null, httpOptions).pipe(
      map((data) => new User(data)),
      tap((user) => {
        this.currentUser = user;
        this.currentUser$.next(user);
      })
    );
  }

  logout(): Observable<boolean> {
    return this.http.get('api/auth/logout').pipe(
      map(() => true),
      tap(() => {
        this.currentUser = null;
        this.currentUser$.next(null);
        this.exerciseService.setActiveExercise(null);
        this.exerciseService.clearBlueTeamCache();
      })
    );
  }

  getCurrentUser(forceRefresh: boolean = false): Observable<User> {
    if (!forceRefresh && this.currentUser) {
      return of(this.currentUser);
    }
    return this.http.get('api/auth/whoami').pipe(
      // An empty object used to be returned in case no auth but now we get nothing.
      // Replicate that behavior for now to avoid any exceptions if we would return null here.
      map((data) => (data ? new User(data) : new User({}))),
      tap((user) => {
        if (user.username) {
          this.currentUser = user;
          this.currentUser$.next(user);
        }
      })
    );
  }

  isUserLoggedIn(): Observable<boolean> {
    return this.getCurrentUser(true).pipe(map((user) => !!user.username));
  }

  hasGamenetPermission(
    roles: RoleCode[],
    exerciseIsRequired: boolean = true,
    useNotifications: boolean = true
  ): Observable<boolean> {
    let currentUser;
    return this.getCurrentUser().pipe(
      mergeMap((user) => {
        currentUser = user;
        return this.exerciseService.getActiveExercise();
      }),
      tap((ex) => {
        if (!ex && exerciseIsRequired) {
          this.router.navigate(['/app/gamenet/exercise']);
          this.notificationsService.info('Module required', 'Please select module');
        }
      }),
      map((exercise) => {
        if (!exercise && exerciseIsRequired) {
          return null;
        }

        for (const role of roles) {
          const permission = getExercisePermissionFromRole(role).withTargets(exercise.id);
          const hasRole = this.userHasPermission(currentUser, permission, true);
          if (hasRole) {
            return true;
          }
        }
        return false;
      }),
      tap((hasRole) => {
        if (useNotifications && hasRole === false) {
          this.notificationsService.error('Access denied', 'Insufficient permissions');
        }
      })
    );
  }

  getRole(exerciseId: string): RoleCode {
    for (const role of Object.keys(ROLES)) {
      const permission = getExercisePermissionFromRole(ROLES[role]).withTargets(exerciseId);
      if (this.userHasPermission(this.currentUser, permission, true)) {
        return ROLES[role];
      }
    }
    return null;
  }

  hasRole(exerciseId: string, role: RoleCode): boolean {
    return this.getRole(exerciseId) === role;
  }

  hasTargetedPermission(
    permission: DomainPermission | string,
    target: string
  ): Observable<boolean> {
    const domainPermission =
      permission instanceof DomainPermission ? permission : new DomainPermission(permission);
    domainPermission.targets = [target];
    return this.hasPermission(domainPermission);
  }

  hasPermission(permission: DomainPermission | string, targetRequired = true): Observable<boolean> {
    const domainPermission =
      permission instanceof DomainPermission ? permission : new DomainPermission(permission);
    return this.getCurrentUser().pipe(
      map((user) => this.userHasPermission(user, domainPermission, targetRequired))
    );
  }

  hasPermissions(permissionConfiguration: PermissionConfiguration): Observable<boolean> {
    return this.getCurrentUser().pipe(
      map((user) => this.userHasPermissions(user, permissionConfiguration))
    );
  }

  userHasPermissions(user: User, permissionConfiguration: PermissionConfiguration): boolean {
    const domainPermissions = permissionConfiguration.permissions.map(
      (it) => new DomainPermission(it.permission)
    );
    const userPermissionFn = (permission) =>
      this.userHasPermission(user, permission, permissionConfiguration.targetRequired);
    return permissionConfiguration.allRequired
      ? domainPermissions.every(userPermissionFn)
      : domainPermissions.some(userPermissionFn);
  }

  private userHasPermission(
    user: User,
    permission: DomainPermission,
    targetRequired: boolean
  ): boolean {
    return (
      user &&
      user.permissions.some((userPermission) => {
        return userPermission.implies(permission, targetRequired);
      })
    );
  }

  clearAuthorizationCache(): Observable<boolean> {
    return this.http.delete('api/auth/cache').pipe(map(() => true));
  }

  private static encodeBase64(data: string): string {
    return btoa(unescape(encodeURIComponent(data)));
  }
}
