<cybexer-widget-header [title]="'Team Status'"></cybexer-widget-header>
<cybexer-widget-body>
  @if (!loading) {
    <isa-status-summary-chart
      [summaryData]="teamStatusChartData.data"
      [labels]="teamStatusChartData.labels"
      (barClicked)="barClicked($event)"
    >
    </isa-status-summary-chart>
  }
  @if (loading) {
    <cybexer-progress-spinner></cybexer-progress-spinner>
  }
</cybexer-widget-body>
