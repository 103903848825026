<div class="exercise-dialog">
  @if (exercise) {
    <h2 matDialogTitle class="exercise-name">
      <span class="exercise-name-text">{{ exercise.name }}</span>
      <div class="exercise-status">
        <span
          class="status-circle"
          [ngClass]="{
            running: exercise.status === 'RUNNING',
            stopped: exercise.status === 'STOPPED',
            'not-started': exercise.status === 'NOT_STARTED',
            unpublished: exercise.status === 'UNPUBLISHED',
            scheduled: exercise.status === 'SCHEDULED'
          }"
        ></span>
        @if (exercise.status === 'RUNNING') {
          <span>Running</span>
        }
        @if (exercise.status === 'STOPPED') {
          <span>Stopped</span>
        }
        @if (exercise.status === 'NOT_STARTED') {
          <span>Not Started</span>
        }
        @if (exercise.status === 'UNPUBLISHED') {
          <span>Unpublished</span>
        }
        @if (exercise.status === 'SCHEDULED') {
          <span>Scheduled</span>
        }
      </div>
    </h2>
    <mat-dialog-content>
      <div class="meta-info">
        @if (exercise.duration) {
          <div><i class="fa fa-stopwatch"></i> {{ exercise.duration | isaDuration }}</div>
        }
        @if (exercise.tasksCount) {
          <div><i class="fa fa-layer-group"></i> {{ exercise.tasksCount }} tasks</div>
        }
        @if (exercise.niceIds && exercise.niceIdsCount) {
          <div><i class="fa fa-check"></i> {{ exercise.niceIdsCount }} nice items</div>
        }
        @if (exercise?.mitreTags?.tags?.length) {
          <div>
            <i class="fa fa-check"></i> {{ exercise.mitreTags.tags.length }} mitre att&ck items
          </div>
        }
      </div>
      <div class="disable-uppercase description">
        {{ exercise.description }}
      </div>
      @if (workRole) {
        <div class="work-role">
          <div class="work-role-title"><i class="fa fa-briefcase"></i>{{ workRole.name }}</div>
          <div class="disable-uppercase description">{{ workRole.description }}</div>
        </div>
      }
      @if (
        exercise?.niceIds?.taskIds?.length ||
        exercise.niceIds?.knowledgeIds?.length ||
        exercise?.niceIds?.skillIds?.length ||
        exercise?.niceIds?.abilityIds?.length
      ) {
        <div class="nice-section">
          @if (exercise?.niceIds) {
            <isa-nice-container
              data-testid="nice-container"
              [niceIds]="exercise?.niceIds"
              [largeLabel]="true"
            ></isa-nice-container>
          }
        </div>
      }
      <div class="nice-section">
        @if (exercise?.mitreTags) {
          <isa-mitre-container
            data-testid="mitre-container"
            [tags]="exercise?.mitreTags"
            [largeLabel]="true"
          ></isa-mitre-container>
        }
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button matDialogClose>Close</button>
    </mat-dialog-actions>
  }
</div>
