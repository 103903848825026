import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {
  CTFTaskDTO,
  CTFTaskStatus,
  SmartCityBuildingDetails,
  SmartCityBuildingSaveDTO,
  SmartCityTargetData,
  SmartCityTaskData,
  Target,
  TargetStatus,
} from '../../../../models';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AuthenticationService, NewsInjectService } from '../../../../services';
import { FilterStateService } from '../../../../shared';
import { SmartCityService } from '../../../../services/gamenet/smart-city.service';
import { NotificationsService } from '@cybexer/ngx-commons';
import { SmartCityMediaType } from 'app/models/gamenet/smart-city-building.model';

@Component({
  selector: 'isa-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrl: './info-panel.component.scss',
})
export class InfoPanelComponent implements OnChanges {
  @Input() exerciseId: string;
  @Input() teamId: string;
  @Input() selectedBuildingDetails: SmartCityBuildingDetails;
  @Input() selectedTask?: SmartCityTaskData;
  @Input() selectedTarget?: SmartCityTargetData;
  @Input() selectedMeshId: string;
  @Input() targets: Target[];
  @Input() selectedCity: string;
  @Input() ctfTasks: CTFTaskDTO[];
  @Output() buildingDataChanged = new EventEmitter<boolean>();
  isAdmin: boolean;
  isEditMode: boolean = false;
  form: FormGroup;
  filteredTargets: Observable<Target[]>;
  filteredTasks: Observable<CTFTaskDTO[]>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  protected readonly StatusMediaType = SmartCityMediaType;
  protected readonly TargetStatus = TargetStatus;
  protected readonly TaskStatus = CTFTaskStatus;

  constructor(
    private authenticationService: AuthenticationService,
    public filterStateService: FilterStateService,
    private fb: FormBuilder,
    public smartCityService: SmartCityService,
    private notificationsService: NotificationsService,
    private newsInjectService: NewsInjectService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.isAdmin = this.authenticationService.currentUser.isAdmin;
    if (changes.selectedBuildingDetails) {
      this.isEditMode = false;
    }

    if (!this.selectedTarget && !this.selectedTask && this.isAdmin) {
      this.initEditMode();
    }
  }

  initEditMode() {
    this.initBuildingForm();
    this.isEditMode = true;
  }

  initBuildingForm() {
    this.form = this.fb.group({
      id: new FormControl(this.selectedMeshId),
      type: new FormControl(
        this.selectedBuildingDetails?.type ? this.selectedBuildingDetails.type : ''
      ),
      address: new FormControl(
        this.selectedBuildingDetails?.address ? this.selectedBuildingDetails.address : ''
      ),
      dependencies: this.fb.array(
        this.selectedBuildingDetails?.dependencies ? this.selectedBuildingDetails.dependencies : []
      ),
      goodMediaId: new FormControl(
        this.selectedBuildingDetails?.media?.good?.id
          ? this.selectedBuildingDetails.media.good.id
          : ''
      ),
      compromisedMediaId: new FormControl(
        this.selectedBuildingDetails?.media?.compromised?.id
          ? this.selectedBuildingDetails.media.compromised.id
          : ''
      ),
      notAvailableMediaId: new FormControl(
        this.selectedBuildingDetails?.media?.notAvailable?.id
          ? this.selectedBuildingDetails.media.notAvailable.id
          : ''
      ),
      target: new FormControl(this.selectedTarget ? this.selectedTarget : null),
      task: new FormControl(this.selectedTask ? this.selectedTask : null),
    });

    this.filteredTargets = this.form.get('target').valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._targetsFilter(name as string) : this.targets.slice();
      })
    );

    this.filteredTasks = this.form.get('task').valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.title;
        return name ? this._tasksFilter(name as string) : this.ctfTasks.slice();
      })
    );
  }

  private _targetsFilter(value: string): Target[] {
    if (!this.targets) return;
    const filterValue = value.toLowerCase();
    return this.targets.filter((target) => target.name.toLowerCase().includes(filterValue));
  }

  private _tasksFilter(value: string): CTFTaskDTO[] {
    if (!this.ctfTasks) return;
    const filterValue = value.toLowerCase();
    return this.ctfTasks.filter((target) => target.title.toLowerCase().includes(filterValue));
  }

  displayTaskFn(task: CTFTaskDTO): string {
    return task && task.title ? task.title : '';
  }

  displayTargetFn(target: Target): string {
    return target && target.name ? target.name : '';
  }

  get dependencies(): FormArray {
    return this.form.get('dependencies') as FormArray;
  }

  add(event: any): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.dependencies.push(this.fb.control(value.trim(), Validators.required));
    }

    if (input) {
      input.value = '';
    }
  }

  remove(index: number): void {
    if (index >= 0) {
      this.dependencies.removeAt(index);
    }
  }

  edit(index: number, event: any): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.dependencies.at(index).setValue(value.trim());
    }

    if (input) {
      input.value = '';
    }
  }

  saveBuildingData() {
    this.smartCityService
      .saveBuildingData(
        this.exerciseId,
        this.teamId,
        this.form.value.id,
        this.createBuildingSaveDTO()
      )
      .subscribe(() => {
        this.notificationsService.success('Building data saved');
        this.buildingDataChanged.emit(true);
        this.closeInfoPanel();
      });
  }

  private createBuildingSaveDTO(): SmartCityBuildingSaveDTO {
    const formData = this.form.value;
    const buildingData = new SmartCityBuildingSaveDTO();
    buildingData.address = formData.address;
    buildingData.type = formData.type;
    buildingData.dependencies = formData.dependencies;
    buildingData.goodMediaId = formData.goodMediaId;
    buildingData.compromisedMediaId = formData.compromisedMediaId;
    buildingData.notAvailableMediaId = formData.notAvailableMediaId;
    buildingData.taskId = formData.task?.id;
    buildingData.targetId = formData.target?.id;

    return buildingData;
  }

  getMediaUrl(mediaId: string) {
    return this.newsInjectService.getMediaUrl(mediaId, this.exerciseId);
  }

  closeInfoPanel() {
    this.smartCityService.isInfoPanelOpen.set(false);
  }
}
