import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationsService } from '@cybexer/ngx-commons';
import {
  AttackReport,
  Exercise,
  IncidentReportConfirmation,
  IncidentReportsData,
  ReportAIAssessment,
} from '../../../../models';
import { AuthenticationService, IncidentReportsService } from '../../../../services';
import { ASSESSMENT_STATUS, CONFIRMATION_STATUS, FormUtil, ROLES } from '../../../../shared';
import { first } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'isa-incident-report-confirm-dialog',
  templateUrl: './incident-report-confirm-dialog.component.html',
  styleUrls: ['./incident-report-confirm-dialog.component.scss'],
})
export class IncidentReportConfirmDialogComponent implements OnInit {
  submitted: boolean;
  incidentReportsData: IncidentReportsData;
  reportDetailsLoaded: boolean = false;
  reportDetails?: string;
  exercise: Exercise;
  aiFeaturesEnabled: boolean = false;
  promptOverrideId?: string;
  CONFIRMATION_STATUS = CONFIRMATION_STATUS;
  feedback: string;
  hasPermissionToConfirmOrDeny = false;

  protected readonly ASSESSMENT_STATUS = ASSESSMENT_STATUS;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IncidentReportConfirmDialogInput,
    private dialogRef: MatDialogRef<IncidentReportConfirmDialogComponent>,
    private incidentReportsService: IncidentReportsService,
    private notificationsService: NotificationsService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.incidentReportsData = this.data.incidentReportsData;
    this.feedback = this.incidentReportsData.feedback || this.incidentReportsData.aiFeedback;
    this.exercise = this.data.exercise;
    this.aiFeaturesEnabled = this.data.aiFeaturesEnabled;
    this.promptOverrideId = this.data.promptOverrideId;

    this.incidentReportsService
      .getReportDetails(this.exercise.id, this.incidentReportsData.reportId)
      .pipe(untilDestroyed(this))
      .subscribe((reportDetails) => {
        this.reportDetails = reportDetails.data;
        this.reportDetailsLoaded = true;
      });

    this.authenticationService
      .hasGamenetPermission([ROLES.ADMIN, ROLES.WHITE], true, false)
      .pipe(untilDestroyed(this))
      .subscribe((x: boolean) => {
        return (this.hasPermissionToConfirmOrDeny = x);
      });
  }

  submitConfirmation(status: string): void {
    this.submitted = true;
    if (status) {
      const incidentReportConfirmation: IncidentReportConfirmation = new IncidentReportConfirmation(
        {
          reportId: this.incidentReportsData.reportId,
          status: status,
          feedback: IncidentReportConfirmDialogComponent.formatFeedback(this.feedback),
        }
      );
      this.incidentReportsService
        .createIncidentReportConfirmation(this.exercise.id, incidentReportConfirmation)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.notificationsService.success(
            `Report has been ${status === CONFIRMATION_STATUS.CONFIRMED ? 'confirmed' : 'denied'}`
          );
          this.dialogRef.close(incidentReportConfirmation);
        });
    }
  }

  showFeedback(): boolean {
    return (
      (this.hasPermissionToConfirmOrDeny &&
        this.incidentReportsData.status === CONFIRMATION_STATUS.PENDING_CONFIRMATION) ||
      (this.incidentReportsData.status !== CONFIRMATION_STATUS.PENDING_CONFIRMATION &&
        !!this.feedback)
    );
  }

  sendToAIAssessment() {
    if (this.incidentReportsData.status !== CONFIRMATION_STATUS.PENDING_CONFIRMATION) return;

    const assessments = [
      new ReportAIAssessment({
        reportId: this.incidentReportsData.reportId,
        teamId: this.incidentReportsData.teamId,
        promptOverrideId: this.promptOverrideId,
      }),
    ];
    this.incidentReportsService
      .sendToAIAssessment(this.exercise.id, assessments)
      .pipe(first())
      .subscribe(() => {
        this.notificationsService.success('Report has been sent to auto-assessment');
        this.dialogRef.close();
      });
  }

  private static formatFeedback(feedback?: string): string {
    if (feedback != null && FormUtil.getStringCharacterCount(feedback.trim()) > 0) {
      return FormUtil.replaceNewlines(feedback, 5, 4);
    }
    return '';
  }
}

export type IncidentReportConfirmDialogInput = {
  incidentReportsData: IncidentReportsData;
  exercise: Exercise;
  aiFeaturesEnabled: boolean;
  promptOverrideId?: string;
};
