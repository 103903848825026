<cybexer-widget-header [title]="'Special Scoring'" [inline]="true">
  <isa-filtered-select
    [options]="teams"
    [preselectedOption]="(filter$ | async).team"
    [optionKey]="'customName'"
    [optionValue]="'id'"
    [defaultOptionKey]="'All ' + (exercise?.isIndividualAssessment ? 'Users' : 'Teams')"
    [label]="exercise?.isIndividualAssessment ? 'Filter by User' : 'Filter by Team'"
    (selectionChange)="filterStateService.setFilter('team', $event)"
  ></isa-filtered-select>
  <div class="spacer"></div>
  <button
    (click)="openSpecialScoringDialog()"
    mat-raised-button
    color="primary"
    class="create-special-scoring-button"
  >
    <i class="fas fa-plus-circle" aria-hidden="true"></i>Add Score
  </button>
</cybexer-widget-header>
<div class="cybexer-table-wrapper">
  <table class="isa-table">
    <thead>
      <tr>
        <th>Time</th>
        <th>{{ exercise?.isIndividualAssessment ? 'User' : 'Team' }}</th>
        <th class="score">Score</th>
        <th>White Member</th>
        <th>Description</th>
      </tr>
    </thead>
    <tbody class="isa-table-body">
      @for (specialScoring of filteredSpecialScorings; track specialScoring) {
        <tr>
          <td class="timestamp">{{ specialScoring.timestamp | isaDate: 'medium' }}</td>
          <td>{{ specialScoring.teamId | isaTeamName }}</td>
          <td class="score">{{ specialScoring.score | isaFixedNumber }}</td>
          <td>
            @if (specialScoring.username) {
              <i class="fas fa-user" aria-hidden="true"></i>
            }
            {{ specialScoring.username }}
          </td>
          <td class="description">
            <span [matTooltip]="specialScoring.description" [matTooltipPosition]="'before'">
              {{ specialScoring.description }}
            </span>
          </td>
        </tr>
      }
    </tbody>
  </table>

  <div>
    @if (!filteredSpecialScorings?.length && !loading) {
      <cybexer-no-data></cybexer-no-data>
    }
  </div>
  @if (loading) {
    <cybexer-progress-spinner></cybexer-progress-spinner>
  }
</div>
