import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationsService } from '@cybexer/ngx-commons';
import { ProductKey } from '../../../models/shared/product-key.model';
import { ProductKeyService } from '../../../services';

@UntilDestroy()
@Component({
  selector: 'isa-product-key',
  templateUrl: './product-key.component.html',
  styleUrls: ['./product-key.component.scss'],
})
export class ProductKeyComponent implements OnInit {
  productKeyForm: UntypedFormGroup;
  processing = false;
  productKey: ProductKey;

  constructor(
    private notificationsService: NotificationsService,
    private productKeyService: ProductKeyService
  ) {}

  ngOnInit() {
    this.productKeyForm = new UntypedFormGroup({
      productKey: new UntypedFormControl(
        this.productKey ? this.productKey.key : '',
        Validators.required
      ),
    });
    this.productKeyService
      .getProductKey()
      .pipe(untilDestroyed(this))
      .subscribe((productKey) => {
        if (productKey.key) {
          this.productKey = productKey;
        }
      });
  }

  save(): void {
    this.processing = true;
    this.productKeyForm.disable();
    this.productKeyService
      .saveProductKey({ value: this.productKeyForm.value.productKey })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (productKey) => {
          this.processing = false;
          this.productKeyForm.enable();
          this.productKey = productKey;
          this.notificationsService.success('Product key saved');
        },
        error: () => {
          this.processing = false;
          this.productKeyForm.enable();
          this.notificationsService.error('Failed to save product key');
        },
      });
  }
}
