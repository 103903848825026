<div class="live-target-dialog">
  <h2 matDialogTitle>Edit target</h2>
  <mat-dialog-content class="live-target-dialog-content">
    <div class="isa-mat-form-row">
      <mat-form-field class="full-width" [attr.data-testid]="'targetNameField'">
        <mat-label>Target name</mat-label>
        <input
          [disabled]="true"
          required
          autocomplete="off"
          type="text"
          matInput
          [value]="target.name"
        />
      </mat-form-field>
    </div>
    <mat-label>Events</mat-label>
    <isa-integration-event-list
      [eventTypes]="[IntegrationEventType.TARGET_STATUS_CHANGE]"
      [subjectType]="IntegrationEventSubjectType.TARGET"
      [subjectId]="target.id"
    ></isa-integration-event-list>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div><button mat-button matDialogClose>Close</button></div>
  </mat-dialog-actions>
</div>
