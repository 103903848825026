import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationsService } from '@cybexer/ngx-commons';
import { Subject } from 'rxjs';
import { Integration } from '../../../../models';
import {
  IntegrationEvent,
  IntegrationEventSaveDTO,
  IntegrationEventSubjectType,
  IntegrationEventType,
} from '../../../../models/shared/integration-event.model';
import { IntegrationsService } from '../../../../services';

@Component({
  selector: 'isa-integration-event-form',
  templateUrl: './integration-event-form.component.html',
  styleUrls: ['./integration-event-form.component.scss'],
})
export class IntegrationEventFormComponent implements OnInit {
  @Input() event?: IntegrationEvent;
  @Input() subjectId: string;
  @Input() subjectType: IntegrationEventSubjectType;
  @Input() allowedEventTypes?: IntegrationEventType[];
  @Input() showSubjectFields?: boolean;
  @Output() onSave = new EventEmitter<IntegrationEvent>();
  @Output() onDelete = new EventEmitter<string>();
  selectedEventType?: IntegrationEventType;
  selectedIntegrationId?: string;
  submit$ = new Subject();
  readonly IntegrationEventType = IntegrationEventType;
  private integrations: Integration[];

  constructor(
    private integrationsService: IntegrationsService,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit() {
    this.integrationsService.getIntegrations().subscribe((integrations) => {
      this.integrations = integrations;
    });
    if (this.event) {
      this.selectedEventType = this.event.type;
      this.selectedIntegrationId = this.event.integrationId;
    }
  }

  get eventTypes(): string[] {
    return this.allowedEventTypes && this.allowedEventTypes.length > 0
      ? this.allowedEventTypes
      : Object.keys(IntegrationEventType);
  }

  get filteredIntegrations(): Integration[] {
    return this.integrations
      ? this.integrations.filter((integration) =>
          integration.eventTypes.has(this.selectedEventType)
        )
      : [];
  }

  get selectedIntegration(): Integration {
    return (
      this.integrations && this.integrations.find((it) => it.id === this.selectedIntegrationId)
    );
  }

  onSubmit(formSaveDTO: IntegrationEventSaveDTO) {
    if (!this.selectedIntegrationId) return;
    const saveDTO = {
      integrationId: this.selectedIntegrationId,
      subjectType: this.event ? this.event.subjectType : this.subjectType,
      subjectId: this.event ? this.event.subjectId : this.subjectId,
      ...formSaveDTO,
    };
    if (this.event && this.event.id) {
      this.integrationsService.updateIntegrationEvent(this.event.id, saveDTO).subscribe(() => {
        this.notificationsService.success('Event updated');
        this.onSave.emit(this.event);
      });
    } else {
      this.integrationsService.createIntegrationEvent(saveDTO).subscribe((savedEvent) => {
        this.notificationsService.success('Event created');
        this.onSave.emit(savedEvent);
      });
    }
  }

  deleteEvent() {
    if (this.event) {
      this.integrationsService.deleteIntegrationEvent(this.event.id).subscribe(() => {
        this.notificationsService.success('Event deleted');
        this.onDelete.emit(this.event.id);
      });
    }
  }
}
