<div class="exercise-image-dialog">
  <h2 matDialogTitle>Exercise image</h2>
  @if (!loading) {
    <div>
      <div class="exercise-image-dialog-content">
        @if (currentImageSrcUrl) {
          <img
            [hidden]="!currentImageLoaded"
            [src]="currentImageSrcUrl | isaSafeUrl"
            (load)="completeLoading()"
            (error)="setError()"
          />
        }
        @if (!currentImageSrcUrl) {
          <form>
            <label class="image-label" for="imageInput">
              <div class="attachment-btn"><i class="fas fa-paperclip" aria-hidden="true"></i></div>
              @if (imageFile && error == null) {
                <i class="fas fa-check approved-icon" aria-hidden="true"></i>
              }
              @if (imageFile == null) {
                <span> Upload image</span>
              }
              @if (imageFile) {
                <span>{{ imageFile.name }} ({{ imageFile.size | isaFileSize }})</span>
              }
            </label>
            <input
              (change)="setFile($event)"
              class="imageInput"
              id="imageInput"
              type="file"
              [accept]="getFileInputAcceptAttr()"
            />
            @if (error) {
              <div class="row error-container">
                <mat-error class="disable-uppercase">{{ error }}</mat-error>
              </div>
            }
            <div class="restriction-info">
              <div>Max file size: {{ maxFileSize | isaFileSize: 0 }}</div>
              <div>
                Supported types:
                <span class="disable-uppercase">{{ getFileInputAcceptAttr() }}</span>
              </div>
            </div>
          </form>
        }
      </div>
      <mat-dialog-actions align="end">
        <div>
          @if (!currentImageSrcUrl) {
            <button
              data-testid="confirm-btn"
              mat-raised-button
              color="primary"
              (click)="uploadImage()"
            >
              <i class="fas fa-upload" aria-hidden="true"></i> Upload
            </button>
          }
          @if (currentImageSrcUrl) {
            <cybexer-confirm-action>
              <button
                data-testid="confirm-btn"
                mat-raised-button
                color="primary"
                (click)="deleteCurrentImage()"
              >
                <i class="fas fa-trash-alt" aria-hidden="true"></i> Delete
              </button>
            </cybexer-confirm-action>
          }
          <button mat-button matDialogClose>Close</button>
        </div>
      </mat-dialog-actions>
    </div>
  }
</div>
