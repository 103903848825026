import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationsService } from '@cybexer/ngx-commons';
import { UserSaveDTO } from '../../../../models';
import { UsersService } from '../../../../services';
import { FormUtil } from '../../../../shared';
import { PasswordFieldsComponent } from '../../../../shared/components/password-fields/password-fields.component';

@Component({
  selector: 'isa-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss'],
})
export class UserDialogComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    private usersService: UsersService,
    private notificationsService: NotificationsService,
    private dialogRef: MatDialogRef<UserDialogComponent>
  ) {}

  ngOnInit() {
    this.form = new UntypedFormGroup({
      username: new UntypedFormControl('', Validators.required),
      fullName: new UntypedFormControl('', Validators.required),
      passwordGroup: PasswordFieldsComponent.createFormGroup(),
    });
  }

  onSubmit() {
    if (this.form.valid) {
      const formData = this.form.value;
      const saveDTO = new UserSaveDTO({
        username: formData.username,
        fullName: formData.fullName,
        password: formData.passwordGroup.password,
      });
      this.usersService.createUser(saveDTO).subscribe({
        next: () => {
          this.notificationsService.success('User created');
          this.dialogRef.close(true);
        },
        error: () => {
          this.notificationsService.error('Could not create user');
        },
      });
      return;
    }

    FormUtil.markFormControlAsTouchedRecursively(this.form);
  }
}
