import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, convertToParamMap, Params, Router } from '@angular/router';
import {
  MonacoCommonsEditorService,
  MonacoJsonEditorComponent,
  NotificationsService,
} from '@cybexer/ngx-commons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { of } from 'rxjs';
import { filter, finalize, switchMap } from 'rxjs/operators';
import { Exercise, IsaErrorMessage, IsaErrorType } from '../../../models';
import {
  ExerciseStatus,
  ExerciseType,
  ExerciseWithStatus,
} from '../../../models/gamenet/exercise.model';
import { ExerciseService, PreferenceService } from '../../../services';
import { ExerciseRecreateWithImageDialogComponent } from './exercise-recreate-with-image-dialog/exercise-recreate-with-image-dialog.component';

@UntilDestroy()
@Component({
  selector: 'isa-exercise-definition',
  templateUrl: './exercise-definition.component.html',
  styleUrls: ['./exercise-definition.component.scss'],
})
export class ExerciseDefinitionComponent implements OnInit {
  // todo: add tests

  exerciseJSON: string;
  exercise: Exercise;
  exerciseStatus: ExerciseStatus;
  processing: boolean;
  selectedTabIndex = 0;
  monacoEditor: MonacoJsonEditorComponent;
  exerciseJsonType = null;
  exerciseRecreateWithImageDialogRef: MatDialogRef<ExerciseRecreateWithImageDialogComponent>;
  jsonSchema: string;
  isNew: boolean = false;

  private validationIsaErrorMessage: IsaErrorMessage;

  constructor(
    private exerciseService: ExerciseService,
    private notificationsService: NotificationsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private preferenceService: PreferenceService,
    private monacoCommonsEditorService: MonacoCommonsEditorService
  ) {}

  ngOnInit() {
    this.processing = true;

    this.activatedRoute.params
      .pipe(
        switchMap((params: Params) => {
          this.isNew = convertToParamMap(params).keys.length === 0;
          return params['exerciseId']
            ? this.exerciseService.getExerciseWithStatus(decodeURIComponent(params['exerciseId']))
            : of(null);
        }),
        switchMap((exWithStatus: ExerciseWithStatus) => {
          this.exercise = exWithStatus?.exercise;
          this.exerciseStatus = exWithStatus?.status;
          if (this.exercise == null) {
            return this.exerciseService.getExerciseJsonSchema(null, true);
          } else {
            this.exerciseJSON = JSON.stringify(this.exercise, null, 4);
            return this.exerciseService.getExerciseJsonSchema(this.exercise.type, false);
          }
        }),
        switchMap((schema) => {
          this.jsonSchema = schema;

          return this.preferenceService.getPreferences();
        }),
        filter((ex) => !!ex),
        untilDestroyed(this)
      )
      .subscribe((pref) => {
        this.processing = false;
        this.monacoCommonsEditorService.setIsLightTheme(pref.isLightTheme);
      });
  }

  onSubmit(): void {
    if (!this.monacoEditor.isEditorValueValid()) return;

    this.monacoEditor.closeActiveMarker();

    if (this.exercise == null) {
      this.createExercise();
      return;
    }

    if (this.selectedTabIndex !== 1) {
      this.updateExercise();
      return;
    }

    if (this.exercise.imageId != null) {
      this.openImageDialog();
      return;
    }

    this.replaceExercise();
  }

  private openImageDialog() {
    this.exerciseRecreateWithImageDialogRef = this.dialog.open(
      ExerciseRecreateWithImageDialogComponent,
      { disableClose: false }
    );

    this.exerciseRecreateWithImageDialogRef.afterClosed().subscribe((result) => {
      this.exerciseRecreateWithImageDialogRef = null;

      if (result === undefined) {
        return;
      }

      if (!result) {
        const exerciseDefinition = JSON.parse(this.exerciseJSON);
        exerciseDefinition.imageId = null;
        this.exerciseJSON = JSON.stringify(exerciseDefinition, null, 2);
      }

      this.replaceExercise();
    });
  }

  private createExercise() {
    this.processing = true;
    this.exerciseService
      .createExercise(this.exerciseJSON)
      .pipe(finalize(() => (this.processing = false)))
      .subscribe({
        next: () => {
          this.notificationsService.success('Module has been created');
          // If API will return created object in resp then we can change null to new exercise
          this.exerciseService.exerciseListChanged.emit(null);
          this.router.navigate(['/app/gamenet/exercise']);
        },
        error: (errorResponse: unknown) => this.handleSubmitError(errorResponse),
      });
  }

  private updateExercise() {
    this.processing = true;
    this.exerciseService
      .updateExercise(this.exerciseJSON)
      .pipe(finalize(() => (this.processing = false)))
      .subscribe({
        next: () => {
          this.notificationsService.success('Module has been updated');
          this.exerciseService.exerciseListChanged.emit(this.exercise);
        },
        error: (errorResponse: unknown) => this.handleSubmitError(errorResponse),
      });
  }

  private replaceExercise() {
    this.processing = true;
    this.exerciseService
      .replaceExercise(this.exercise.id, this.exerciseJSON)
      .pipe(finalize(() => (this.processing = false)))
      .subscribe({
        next: () => {
          this.notificationsService.success('Module has been recreated');
          this.exerciseService.exerciseListChanged.emit(this.exercise);
          const activeExercise = this.exerciseService.activeExercise.getValue();
          if (activeExercise && activeExercise.id === this.exercise.id) {
            this.exerciseService.setActiveExercise(null);
          }
          this.router.navigate(['/app/gamenet/exercise']);
        },
        error: (errorResponse: unknown) => this.handleSubmitError(errorResponse),
      });
  }

  handleSubmitError(errorResponse: unknown) {
    let errorMsg = 'Check module definition JSON';
    const errorTitle = 'Error occurred';

    if (errorResponse instanceof HttpErrorResponse) {
      const isaErrorMessage = errorResponse.error ? new IsaErrorMessage(errorResponse.error) : null;
      if (isaErrorMessage === null) {
        this.notificationsService.error(errorTitle, errorMsg);
        return;
      }
      if (
        isaErrorMessage.type !== IsaErrorType.INTERNAL_ERROR &&
        isaErrorMessage.type !== IsaErrorType.VALIDATION_ERROR
      ) {
        errorMsg = errorResponse.error.error;
      }
      if (isaErrorMessage.type === IsaErrorType.VALIDATION_ERROR) {
        this.validationIsaErrorMessage = isaErrorMessage;
      }
    }

    this.notificationsService.error(errorTitle, errorMsg);
  }

  tabSelectionChange(index: number) {
    this.selectedTabIndex = index;
    if (index === 1) {
      this.exerciseJSON = this.exercise.definition
        ? JSON.stringify(this.exercise.definition, null, 4)
        : '';
    } else {
      this.exerciseJSON = JSON.stringify(this.exercise, null, 4);
    }

    this.monacoEditor.removeErrorOverlayWidget();
    this.monacoEditor.closeActiveMarker();
    this.loadNewExerciseJsonSchema(index === 1, this.exercise.type);
  }

  isExerciseJsonEmpty() {
    return this.exerciseJSON === undefined || this.exerciseJSON.trim().length === 0;
  }

  editorInitialized(editor) {
    if (!editor) return;

    this.monacoEditor = editor;

    if (this.exercise !== null) {
      this.monacoEditor.editor.onDidChangeModelDecorations(() => {
        this.isNewSchemaLoadPossible();
      });
    }

    if (this.validationIsaErrorMessage) {
      this.monacoEditor.displayValidationErrorMessage(this.validationIsaErrorMessage.error);
      this.validationIsaErrorMessage = undefined;
    }
  }

  loadNewSchema() {
    this.monacoEditor.closeActiveMarker();
    this.loadNewExerciseJsonSchema(
      this.isDefinitionTabOpened() || this.isNew,
      this.exerciseJsonType,
      true
    );
    this.exerciseJsonType = null;
  }

  private loadNewExerciseJsonSchema(
    isExerciseDefinition: boolean,
    exerciseType: ExerciseType = null,
    hasExerciseTypeChanged: boolean = false
  ) {
    this.exerciseService.getExerciseJsonSchema(exerciseType, isExerciseDefinition).subscribe({
      next: (data) => {
        this.jsonSchema = data;

        if (hasExerciseTypeChanged) {
          this.notificationsService.success(
            'Schema loaded successfully',
            `${exerciseType} schema loaded`
          );
        }
      },
      error: () => {
        this.notificationsService.error(
          'Schema load error',
          `${exerciseType} schema could not be loaded`
        );
      },
    });
  }

  private isNewSchemaLoadPossible() {
    if (this.monacoEditor.tryParseJSONObject(this.monacoEditor.value)) {
      const validExerciseObjectType = JSON.parse(this.monacoEditor.value).type;
      if (Object.values(ExerciseType).includes(validExerciseObjectType)) {
        if (
          this.monacoEditor.jsonSchema !== undefined &&
          this.monacoEditor.jsonSchema['title'] !== validExerciseObjectType
        ) {
          this.exerciseJsonType = validExerciseObjectType;
        }
      } else {
        this.exerciseJsonType = null;
      }
    }
  }

  downloadExerciseJsonSchema() {
    this.monacoEditor.downloadJsonSchema(this.isDefinitionTabOpened());
  }

  isSubmitButtonDisabled() {
    if (this.processing || this.isExerciseJsonEmpty()) {
      return true;
    }

    if (this.isDefinitionTabOpened()) {
      return this.exerciseStatus !== ExerciseStatus.NOT_STARTED;
    }

    return false;
  }

  getSubmitButtonTooltip(): string {
    if (this.processing) {
      return 'Processing';
    }
    if (this.isExerciseJsonEmpty()) {
      return 'Json template is empty';
    }

    if (this.isDefinitionTabOpened() && this.exerciseStatus != ExerciseStatus.NOT_STARTED) {
      return 'Reset before recreating.';
    }

    return '';
  }

  private isDefinitionTabOpened(): boolean {
    return this.selectedTabIndex === 1;
  }
}
