<div class="team-ranking">
  <mat-card>
    <div widgetHandle class="live-card-title">
      <i class="fas fa-list-ol" aria-hidden="true"></i> Team ranking live
    </div>
    @if (relativeTeamRankings.length === 0 && dataLoaded) {
      <div class="no-data">No data to display</div>
    }
    @for (team of relativeTeamRankings; track team) {
      <div class="ranking-row" [ngClass]="{ 'active-team': team.teamId === teamId }">
        <div class="ranking">{{ team.position }}.</div>
        <div class="team">{{ team.teamId | isaTeamName }}</div>
        <div class="score nowrap">[Score: {{ team.score | isaFixedNumber }}]</div>
      </div>
    }
  </mat-card>
</div>
