import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationsService } from '@cybexer/ngx-commons';
import {
  Exercise,
  TaskReportConfirmation,
  TaskReportDetails,
  TaskReportsData,
} from '../../../../../models';
import {
  AuthenticationService,
  ExerciseService,
  TaskReportsService,
} from '../../../../../services';
import { CONFIRMATION_STATUS, CustomValidators, FormUtil, ROLES } from '../../../../../shared';

@UntilDestroy()
@Component({
  selector: 'isa-task-report-confirm-dialog',
  templateUrl: './task-report-confirm-dialog.component.html',
  styleUrls: ['./task-report-confirm-dialog.component.scss'],
})
export class TaskReportConfirmDialogComponent implements OnInit {
  private static SCORE_PERCENTAGE_DEFAULT_VALUE = 100;
  submitted: boolean;
  taskReportsData: TaskReportsData;
  taskReportDetails: TaskReportDetails;
  exercise: Exercise;
  taskConfirmationReportForm: UntypedFormGroup;
  CONFIRMATION_STATUS = CONFIRMATION_STATUS;
  hasPermissionToConfirmOrDeny = false;
  isExerciseRunning = false;
  loading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TaskReportConfirmDialogComponent>,
    private taskReportsService: TaskReportsService,
    private notificationsService: NotificationsService,
    private customValidators: CustomValidators,
    private authenticationService: AuthenticationService,
    private exerciseService: ExerciseService
  ) {}

  ngOnInit(): void {
    this.taskReportsData = this.data['taskReportsData'];
    this.exercise = this.data['exercise'];
    this.calculateIsExerciseRunning();

    this.taskReportsService
      .getTaskReportDetails(
        this.exercise.id,
        this.taskReportsData.teamId,
        this.taskReportsData.taskId,
        this.taskReportsData.reportId
      )
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.taskReportDetails = res;
        this.loading = false;
      });

    this.authenticationService
      .hasGamenetPermission([ROLES.ADMIN, ROLES.WHITE], true, false)
      .pipe(untilDestroyed(this))
      .subscribe((x: boolean) => {
        return (this.hasPermissionToConfirmOrDeny = x);
      });

    this.taskConfirmationReportForm = new UntypedFormGroup({
      scorePercentage: new UntypedFormControl(
        {
          value: this.taskReportsData.scorePercentage
            ? this.taskReportsData.scorePercentage
            : TaskReportConfirmDialogComponent.SCORE_PERCENTAGE_DEFAULT_VALUE,
          disabled: this.taskReportsData.status !== CONFIRMATION_STATUS.PENDING_CONFIRMATION,
        },
        [Validators.required, this.customValidators.integer({ min: 0, max: 100 })]
      ),
      feedback: new UntypedFormControl({
        value: this.taskReportsData.feedback,
        disabled:
          !this.hasPermissionToConfirmOrDeny ||
          this.taskReportsData.status !== CONFIRMATION_STATUS.PENDING_CONFIRMATION,
      }),
    });
  }

  get scorePercentageControl() {
    return this.taskConfirmationReportForm.get('scorePercentage');
  }

  get score() {
    if (this.scorePercentageControl.value === 0) {
      return 0;
    }

    return Math.round(
      (this.scorePercentageControl.value * this.taskReportsData.initialScore) / 100
    );
  }

  private calculateIsExerciseRunning() {
    this.exerciseService
      .getExerciseDuration(this.exercise.id)
      .pipe(untilDestroyed(this))
      .subscribe((duration) => {
        this.isExerciseRunning = duration.startexEvents.length > duration.endexEvents.length;
      });
  }

  onSubmit(status: string): void {
    this.submitted = true;
    const form = this.taskConfirmationReportForm;
    if (status && form.valid) {
      const taskReportConfirmation: TaskReportConfirmation = new TaskReportConfirmation({
        reportId: this.taskReportsData.reportId,
        taskId: this.taskReportsData.taskId,
        exerciseId: this.exercise.id,
        teamId: this.taskReportsData.teamId,
        scorePercentage: status === CONFIRMATION_STATUS.DENIED ? 0 : form.value.scorePercentage,
        feedback: TaskReportConfirmDialogComponent.formatFeedback(form.value.feedback),
        status: status,
      });
      this.taskReportsService
        .createTaskReportConfirmation(taskReportConfirmation)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.notificationsService.success(`Report has been ${status.toLocaleLowerCase()}`);
          this.dialogRef.close(taskReportConfirmation);
        });
    }
  }

  showFeedback(): boolean {
    return (
      (this.hasPermissionToConfirmOrDeny &&
        this.taskReportsData.status === CONFIRMATION_STATUS.PENDING_CONFIRMATION) ||
      (this.taskReportsData.status !== CONFIRMATION_STATUS.PENDING_CONFIRMATION &&
        this.taskConfirmationReportForm.controls['feedback'].value)
    );
  }

  private static formatFeedback(feedback?: string): string {
    if (feedback != null && FormUtil.getStringCharacterCount(feedback.trim()) > 0) {
      return FormUtil.replaceNewlines(feedback, 5, 4);
    }
    return '';
  }
}
