import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationsService } from '@cybexer/ngx-commons';
import { CampaignLiveNetworkSegment, NetworkSegment } from '../../../../../models';
import { ExerciseService } from '../../../../../services';

@Component({
  selector: 'isa-network-segment-dialog',
  templateUrl: './network-segment-dialog.component.html',
  styleUrls: ['./network-segment-dialog.component.scss'],
})
export class NetworkSegmentDialogComponent implements OnInit {
  segmentFormGroup: UntypedFormGroup;
  networkSegment: CampaignLiveNetworkSegment;
  borderColors = ['#4B5267', '#8661C1', '#BE97C6', '#2E294E', '#EFBCD5', '#635CC1'];
  segmentIndex: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<NetworkSegmentDialogComponent>,
    private notificationsService: NotificationsService,
    private exerciseService: ExerciseService
  ) {}

  ngOnInit() {
    this.networkSegment = this.data.networkSegment;
    this.segmentIndex = this.data.segmentIndex;
    this.segmentFormGroup = new UntypedFormGroup({
      name: new UntypedFormControl({ value: this.networkSegment.name, disabled: true }),
      color: new UntypedFormControl(
        this.networkSegment.color ? this.networkSegment.color : this.getBorderColor()
      ),
    });
  }

  onSubmit(form: UntypedFormGroup) {
    this.networkSegment.color = form.value.color;
    this.exerciseService
      .updateNetworkSegment(
        this.data.exerciseId,
        new NetworkSegment({
          id: this.networkSegment.id,
          name: this.networkSegment.name,
          color: form.value.color,
        })
      )
      .subscribe(() => {
        this.notificationsService.success('Network segment updated');
        this.dialogRef.close(form.value.color);
      });
  }

  getBorderColor(): string {
    if (this.segmentIndex < this.borderColors.length) {
      return this.borderColors[this.segmentIndex];
    } else {
      return '#727272';
    }
  }
}
