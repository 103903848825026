<div class="campaign-live-widget">
  <mat-card class="campaign-live-widget-container">
    <div widgetHandle class="live-card-title">
      <i class="fas fa-th" aria-hidden="true"></i> Campaign Live
    </div>
    @if (campaignLive) {
      @if (dataLoaded) {
        <div class="live-card-team-status">
          <div widgetHandle class="red-team">
            <div class="red-team-icon"><i class="fas fa-user-secret" aria-hidden="true"></i></div>
            @if (isUnderAttack) {
              <div
                class="red-team-arrows"
                [style.height.px]="attackArrowSize"
                [style.width.px]="attackArrowSize"
              ></div>
            }
          </div>
          <mat-card class="team-card">
            <div>
              <div class="team-card-chart-container">
                <div
                  class="chart-good"
                  [style.height.%]="(campaignLive.states.good / campaignLive.states.total) * 100"
                ></div>
                <div
                  class="chart-compromised"
                  [style.height.%]="
                    (campaignLive.states.compromised / campaignLive.states.total) * 100
                  "
                ></div>
                <div
                  class="chart-not-available"
                  [style.height.%]="
                    (campaignLive.states.notAvailable / campaignLive.states.total) * 100
                  "
                ></div>
              </div>
            </div>
            <div class="team-info">
              <div class="team-name">{{ campaignLive.teamId | isaTeamName }}</div>
              <div class="score">Score: {{ campaignLive.score | isaFixedNumber }}</div>
              <div class="score">Ranking: {{ campaignLive.ranking }}</div>
              <div class="states">
                <div>
                  Good: {{ getTargetChecksPercentageStatus(campaignLive.states.roundedGood) }}
                </div>
                @if (exercise.type !== ExerciseType.VERSUS) {
                  <div>
                    Compromised:
                    {{ getTargetChecksPercentageStatus(campaignLive.states.roundedCompromised) }}
                  </div>
                }
                <div>
                  Not Available:
                  {{ getTargetChecksPercentageStatus(campaignLive.states.roundedNotAvailable) }}
                </div>
              </div>
            </div>
            <div class="segments">
              @for (segment of campaignLive.networkSegments; track segment; let i = $index) {
                <div>
                  <div
                    class="segment"
                    [class.with-left-border]="segment.states.total === 0"
                    [style.border-top-color]="getBorderColor(segment.id)"
                  >
                    @if (currentUser?.isAdmin || currentUser?.isGamenetAdmin(exercise.id)) {
                      <div (click)="openNetworkSegmentDialog(segment)" class="edit-segment">
                        <i class="fas fa-edit"></i>
                      </div>
                    }
                    @if (segment.states.total > 0) {
                      <div class="segment-stacked-chart-container">
                        <div
                          class="chart-good"
                          [style.height.%]="(segment.states.good / segment.states.total) * 100"
                        ></div>
                        <div
                          class="chart-compromised"
                          [style.height.%]="
                            (segment.states.compromised / segment.states.total) * 100
                          "
                        ></div>
                        <div
                          class="chart-not-available"
                          [style.height.%]="
                            (segment.states.notAvailable / segment.states.total) * 100
                          "
                        ></div>
                      </div>
                    }
                    <div style="position: relative">
                      <i class="fas fa-sitemap" aria-hidden="true"></i>
                      @if (segment.isUnderAttack) {
                        <i class="fas fa-bolt segment-under-attack" aria-hidden="true"></i>
                      }
                    </div>
                    <div class="segment-name">{{ segment.name }}</div>
                  </div>
                </div>
              }
            </div>
          </mat-card>
        </div>
      }
      <div class="targets">
        @if (!isCompactView) {
          <mat-accordion class="isa-content-accordion" multi="true">
            @for (targetGroup of targetGroupsUIState; track targetGroup; let i = $index) {
              <mat-expansion-panel
                [(expanded)]="targetGroupsExpansionStates[i]"
                class="target-group"
              >
                <mat-expansion-panel-header expandedHeight="40px" collapsedHeight="40px">
                  <mat-panel-title class="target-group-info">
                    @if (targetGroup.isUnderAttack) {
                      <i class="fas fa-bolt under-attack" aria-hidden="true"></i>
                    }
                    <span class="targets-title ellipsis">{{ targetGroup.name }}</span>
                    <span class="targets-length">{{ targetGroup.targets.length }} targets</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                  @for (target of targetGroup.targets; track target; let i = $index) {
                    <isa-campaign-live-target
                      [target]="target"
                      [borderColor]="getBorderColor(target.networkSegmentId)"
                      [isVersusExercise]="exercise.type === ExerciseType.VERSUS"
                      [isTargetExpanded]="target.isUnderAttack"
                    ></isa-campaign-live-target>
                  }
                </div>
              </mat-expansion-panel>
            }
          </mat-accordion>
        }
        @if (isCompactView) {
          <mat-card class="compact-targets-container">
            <div class="compact-targets-count">{{ getNumberOfTargets() }} targets</div>
            @for (targetGroup of targetGroupsUIState; track targetGroup; let i = $index) {
              @for (target of targetGroup.targets; track target; let i = $index) {
                <isa-campaign-live-target
                  [target]="target"
                  [borderColor]="getBorderColor(target.networkSegmentId)"
                  [isVersusExercise]="exercise.type === ExerciseType.VERSUS"
                  [isTargetExpanded]="false"
                ></isa-campaign-live-target>
              }
            }
          </mat-card>
        }
      </div>
    }
    @if (((!campaignLive || isEmpty(campaignLive)) && dataLoaded) || !teamId) {
      <div class="no-data">No data to display</div>
    }
    @if (!dataLoaded && teamId) {
      <cybexer-progress-spinner></cybexer-progress-spinner>
    }
  </mat-card>
</div>
