<mat-card class="info-panel">
  <button
    class="close-info-panel-btn"
    (click)="closeInfoPanel()"
    mat-icon-button
    aria-label="close infopanel"
  >
    <mat-icon>close</mat-icon>
  </button>
  @if (selectedBuildingDetails && !isEditMode) {
    @if (isAdmin) {
      <div class="building-type-row">
        <span>({{ selectedMeshId }})</span>
        <button (click)="initEditMode()" class="edit-building-btn" mat-icon-button>
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    }
    @switch (selectedTarget?.status) {
      @case (TargetStatus.GOOD) {
        @if (selectedBuildingDetails.media?.good) {
          @if (selectedBuildingDetails.media.good.type === StatusMediaType.IMAGE) {
            <img
              class="media"
              data-testid="img"
              [src]="getMediaUrl(selectedBuildingDetails.media.good.id) | isaSafeUrl"
              alt=""
            />
          } @else {
            <video
              class="media"
              controls
              autoplay
              width="400"
              loop
              [muted]="'muted'"
              data-testid="video"
            >
              <source
                [src]="getMediaUrl(selectedBuildingDetails.media.good.id) | isaSafeUrl"
                type="video/mp4"
              />
            </video>
          }
        } @else {
          <ng-container
            *ngTemplateOutlet="cityImageTemplate; context: { city: selectedCity }"
          ></ng-container>
        }
      }
      @case (TargetStatus.COMPROMISED) {
        @if (selectedBuildingDetails.media?.compromised) {
          @if (selectedBuildingDetails.media.compromised.type === StatusMediaType.IMAGE) {
            <img
              class="media"
              data-testid="img"
              [src]="getMediaUrl(selectedBuildingDetails.media.compromised.id) | isaSafeUrl"
              alt=""
            />
          } @else {
            <video
              class="media"
              controls
              autoplay
              width="400"
              loop
              [muted]="'muted'"
              data-testid="video"
            >
              <source
                [src]="getMediaUrl(selectedBuildingDetails.media.compromised.id) | isaSafeUrl"
                type="video/mp4"
              />
            </video>
          }
        } @else {
          <ng-container
            *ngTemplateOutlet="cityImageTemplate; context: { city: selectedCity }"
          ></ng-container>
        }
      }
      @case (TargetStatus.NOT_AVAILABLE) {
        @if (selectedBuildingDetails.media?.notAvailable) {
          @if (selectedBuildingDetails.media.notAvailable.type === StatusMediaType.IMAGE) {
            <img
              class="media"
              data-testid="img"
              [src]="getMediaUrl(selectedBuildingDetails.media.notAvailable.id) | isaSafeUrl"
              alt=""
            />
          } @else {
            <video
              class="media"
              controls
              autoplay
              width="400"
              loop
              [muted]="'muted'"
              data-testid="video"
            >
              <source
                [src]="getMediaUrl(selectedBuildingDetails.media.notAvailable.id) | isaSafeUrl"
                type="video/mp4"
              />
            </video>
          }
        } @else {
          <ng-container
            *ngTemplateOutlet="cityImageTemplate; context: { city: selectedCity }"
          ></ng-container>
        }
      }
      @default {
        <img
          src="{{ selectedCity === 'Tartu' ? 'assets/img/tartu.png' : 'assets/img/baltimore.png' }}"
        />
      }
    }
    <div>
      <div class="building-type-row">
        {{ selectedBuildingDetails.type }}
        @if (selectedTarget?.isUnderAttack === true) {
          <span>-</span>
          <i class="fas fa-bolt fa-fade under-attack" aria-hidden="true"></i>
          <span>UNDER ATTACK</span>
        }
      </div>
      <div class="disable-uppercase tone-down">
        {{ selectedBuildingDetails?.address }}
      </div>
    </div>
    @if (selectedTarget) {
      <div class="target">
        <div class="thin">Target</div>
        <div>{{ selectedTarget?.name }}</div>
        @for (targetCheck of selectedTarget?.targetChecks; track targetCheck.id) {
          <div class="target-check disable-uppercase">
            <span
              class="target-check-state"
              [ngClass]="{
                'target-check-state__good': targetCheck.status === TargetStatus.GOOD,
                'target-check-state__compromised': targetCheck.status === TargetStatus.COMPROMISED,
                'target-check-state__not-available':
                  targetCheck.status === TargetStatus.NOT_AVAILABLE
              }"
            >
              @if (targetCheck.status === TargetStatus.GOOD) {
                <i class="fas fa-check-circle" aria-hidden="true" [matTooltip]="'GOOD'"></i>
              } @else if (targetCheck.status === TargetStatus.COMPROMISED) {
                <i
                  class="fas fa-exclamation-circle"
                  aria-hidden="true"
                  [matTooltip]="'COMPROMISED'"
                ></i>
              } @else if (targetCheck.status === TargetStatus.NOT_AVAILABLE) {
                <i
                  class="fas fa-minus-circle"
                  aria-hidden="true"
                  [matTooltip]="'NOT AVAILABLE'"
                ></i>
              }
            </span>
            {{ targetCheck.name }}
          </div>
        }
      </div>
    }
    @if (selectedTask) {
      <div>
        <div class="thin">Task</div>
        <div class="task">
          <div>{{ selectedTask.title }} ({{ selectedTask.score }} pts)</div>
          <div
            [ngClass]="{
              'good-color': selectedTask.status === TaskStatus.SOLVED,
              'compromised-color':
                selectedTask.status === TaskStatus.PARTLY_SOLVED ||
                selectedTask.status === TaskStatus.VALIDATING,
              'not-available-color':
                selectedTask.status === TaskStatus.ABANDONED ||
                selectedTask.status === TaskStatus.NOT_STARTED ||
                selectedTask.status === TaskStatus.DEPENDENCIES_UNSOLVED
            }"
            class="task-status"
          >
            {{ selectedTask.status }}
          </div>
        </div>
      </div>
    }

    @if (selectedBuildingDetails.dependencies.length) {
      <div>
        <div class="thin">Dependencies</div>
        <mat-chip-set aria-label="Fish selection">
          @for (dependency of selectedBuildingDetails.dependencies; track dependency) {
            <mat-chip>{{ dependency }}</mat-chip>
          }
        </mat-chip-set>
      </div>
    }
  }
  <ng-template #cityImageTemplate let-city="city">
    <img [src]="city === 'Tartu' ? 'assets/img/tartu.png' : 'assets/img/baltimore.png'" />
  </ng-template>

  @if (isEditMode) {
    EDIT BUILDING {{ selectedMeshId }}
    <form class="building-form" [formGroup]="form">
      <mat-form-field>
        <mat-label>Address</mat-label>
        <input formControlName="address" matInput />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Type</mat-label>
        <input formControlName="type" matInput />
      </mat-form-field>
      @if (targets) {
        <mat-form-field class="example-full-width">
          <mat-label>Target</mat-label>
          <input
            type="text"
            placeholder="Pick one"
            aria-label="Target"
            matInput
            formControlName="target"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayTargetFn">
            @for (target of filteredTargets | async; track target.id) {
              <mat-option [value]="target">{{ target.name }}</mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      }
      @if (ctfTasks) {
        <mat-form-field class="example-full-width">
          <mat-label>Task</mat-label>
          <input
            type="text"
            placeholder="Pick one"
            aria-label="Ctf task"
            matInput
            formControlName="task"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayTaskFn">
            @for (task of filteredTasks | async; track task.id) {
              <mat-option [value]="task">{{ task.title }}</mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      }
      <mat-form-field class="example-chip-list">
        <mat-label>Dependencies</mat-label>
        <mat-chip-grid #chipGrid aria-label="Enter dependencies">
          @for (dependency of dependencies.controls; track dependency; let i = $index) {
            <mat-chip-row
              (removed)="remove(i)"
              [editable]="true"
              (edited)="edit(i, $event)"
              [aria-description]="'press enter to edit ' + dependency.value"
            >
              {{ dependency.value }}
              <button matChipRemove [attr.aria-label]="'remove ' + dependency.value">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          }
          <input
            placeholder="New dependency..."
            [matChipInputFor]="chipGrid"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="add($event)"
          />
        </mat-chip-grid>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Good Status Media id</mat-label>
        <input formControlName="goodMediaId" matInput />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Compromised Status Media id</mat-label>
        <input formControlName="compromisedMediaId" matInput />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Not Available Media id</mat-label>
        <input formControlName="notAvailableMediaId" matInput />
      </mat-form-field>
    </form>
    <div class="action-buttons">
      <button (click)="saveBuildingData()" mat-raised-button color="primary">Save</button>
      <button (click)="closeInfoPanel()" mat-raised-button>Cancel</button>
    </div>
  }
</mat-card>
