import { Pipe, PipeTransform } from '@angular/core';
import { CTFTaskStatus } from '../../models';

@Pipe({
  name: 'taskStatusText',
})
export class TaskStatusTextPipe implements PipeTransform {
  transform(status: CTFTaskStatus) {
    switch (status) {
      case CTFTaskStatus.IN_PROGRESS:
      case CTFTaskStatus.ATTACK_ABANDONED:
        return 'Open';
      case CTFTaskStatus.NOT_STARTED:
        return 'Not started';
      case CTFTaskStatus.ABANDONED:
        return 'On hold';
      case CTFTaskStatus.SOLVED:
        return 'Solved';
      case CTFTaskStatus.PARTLY_SOLVED:
        return 'Partly solved';
      case CTFTaskStatus.DEPENDENCIES_UNSOLVED:
      case CTFTaskStatus.LOCKED:
        return 'Locked';
      case CTFTaskStatus.VALIDATING:
        return 'Validating';
      case CTFTaskStatus.ATTACK_IN_PROGRESS:
        return 'Active attack';
      default:
        return 'Unknown';
    }
  }
}
