<div class="exercise-confirm-dialog">
  <mat-dialog-content class="exercise-confirm-dialog-content">
    <div>
      Are you sure you want to
      @if (eventType === 'STARTEX') {
        <span> start </span>
      }
      @if (eventType === 'ENDEX') {
        <span> stop </span>
      }
      {{ exercise?.name }}
      <mat-form-field class="full-width textarea message">
        <mat-label>Message</mat-label>
        <textarea [(ngModel)]="message" matInput [cdkTextareaAutosize] tabindex="-1"></textarea>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div>
      @if (eventType === 'STARTEX') {
        <button (click)="confirmEvent()" mat-raised-button color="primary">Start</button>
      }
      @if (eventType === 'ENDEX') {
        <button (click)="confirmEvent()" mat-raised-button color="primary">Stop</button>
      }
      <button mat-button matDialogClose>Cancel</button>
    </div>
  </mat-dialog-actions>
</div>
