import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NotificationsService } from '@cybexer/ngx-commons';
import moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { GmaOverview, GmaStatus } from '../../../models';
import { TooltipStatus } from '../../../models/shared/gma-overview.model';
import { GmaService, IntervalService } from '../../../services';
import { ADMIN_PERMISSIONS } from '../../../shared';
import Utils from '../../../shared/utils';
import { GmaDetailsDialogComponent } from './gma-details-dialog/gma-details-dialog.component';

@Component({
  selector: 'isa-gma-overview',
  templateUrl: './gma-overview.component.html',
  styleUrls: ['./gma-overview.component.scss'],
})
export class GmaOverviewComponent implements OnInit {
  private static REFRESH_INTERVAL = 10000;

  loading = true;
  gmaOverviews: GmaOverview[] = [];
  gmaDetailsDialogRef: MatDialogRef<GmaDetailsDialogComponent>;
  readonly ADMIN_PERMISSIONS = ADMIN_PERMISSIONS;
  readonly GMA_STATUS = GmaStatus;

  constructor(
    private gmaService: GmaService,
    private notificationsService: NotificationsService,
    private dialog: MatDialog,
    private intervalService: IntervalService
  ) {}

  ngOnInit(): void {
    this.setData();

    this.intervalService
      .getInterval(GmaOverviewComponent.REFRESH_INTERVAL)
      .subscribe(() => this.setData());
  }

  private setData(): void {
    this.gmaService
      .getGmaOverviews()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((it) => (this.gmaOverviews = it));
  }

  openGmaDetailsDialog(gmaOverview: GmaOverview): void {
    this.gmaDetailsDialogRef = this.dialog.open(GmaDetailsDialogComponent, {
      disableClose: false,
      data: { gmaOverview: gmaOverview },
    });
  }

  getMatTooltipText(gmaOverview: GmaOverview): string {
    const status = GmaOverviewComponent.getTooltipStatusText(gmaOverview.status);

    const activity = gmaOverview.latestActivityTimestamp
      ? moment(gmaOverview.latestActivityTimestamp).format('L LTS')
      : '-';
    const poll = gmaOverview.latestGmaPollTimestamp
      ? moment(gmaOverview.latestGmaPollTimestamp).format('L LTS')
      : '-';

    return (
      status + '\n' + 'Last activity message: ' + activity + '\n' + 'Last successful poll: ' + poll
    );
  }

  async openGmaDeleteDialog(gmaId: string): Promise<void> {
    if (!(await Utils.confirmed(this.dialog, 'Are you sure you want to delete this GMA?'))) {
      return;
    }

    const deleted = await firstValueFrom(this.gmaService.deleteGma(gmaId));

    if (deleted) {
      this.gmaOverviews = this.gmaOverviews.filter((it) => it.id !== gmaId);
      this.notificationsService.success('GMA deleted');
    }
  }

  private static getTooltipStatusText(status: GmaStatus): TooltipStatus {
    switch (status) {
      case GmaStatus.POWER_OFF:
        return TooltipStatus.POWER_OFF;

      case GmaStatus.BROADCAST:
        return TooltipStatus.BROADCAST;

      case GmaStatus.HEARTBEAT:
        return TooltipStatus.HEARTBEAT;

      case GmaStatus.EXCLAMATION:
        return TooltipStatus.EXCLAMATION;

      case GmaStatus.UNKNOWN: {
        return TooltipStatus.UNKNOWN;
      }
    }
  }
}
