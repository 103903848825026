@if (!loading) {
  <div class="queue-statistics">
    <mat-card class="exercise">
      <div class="title">{{ exercise.name }}</div>
      <div class="subtitle">automatic queue based exercise</div>
    </mat-card>
    <mat-card class="max-statistics">
      <div class="title">Current wait time</div>
      @if (!currentWaitTime) {
        <div class="wait-duration">-</div>
      }
      @if (currentWaitTime) {
        <div class="wait-duration">{{ currentWaitTime | isaDuration }}</div>
      }
    </mat-card>
    <mat-card class="timeline-container">
      <canvas
        [attr.data-testid]="'line-chart'"
        baseChart
        #timelineChart="base-chart"
        [datasets]="chartData"
        [labels]="chartLabels"
        [options]="lineChartOptions"
        [type]="'line'"
        [style.height.px]="chartHeight"
        [style.max-height.px]="chartHeight"
        (window:resize)="onResize($event)"
      >
      </canvas>
    </mat-card>
    <mat-accordion class="isa-content-accordion accordion-container">
      <mat-expansion-panel
        [expanded]="openedPanelIndex === 0"
        [hideToggle]="openedPanelIndex === 0"
        (opened)="setOpenedPanel(0)"
      >
        <mat-expansion-panel-header collapsedHeight="120px" expandedHeight="120px">
          <div class="row">
            <div class="doughnut-chart-container">
              <canvas
                baseChart
                [attr.data-testid]="'doughnut-chart'"
                [datasets]="doughnutChartData"
                [type]="'doughnut'"
                [options]="doughnutChartOptions"
              >
              </canvas>
            </div>
            <div class="header-text-container">
              {{ data.allocatedTeams.length }}/{{ exercise.blueTeams.length }} Teams allocated
            </div>
          </div>
        </mat-expansion-panel-header>
        <div class="users-list">
          @if (data.allocatedTeams.length === 0) {
            <cybexer-no-data></cybexer-no-data>
          }
          @for (team of data.allocatedTeams; track team.teamId) {
            <mat-card class="user-card">
              <div>
                <div class="team-name">{{ getTeamName(team.teamId) }}</div>
                <div class="reset-btn-container">
                  <button
                    mat-menu-item
                    *isaRequirePermission="EXERCISE_PERMISSIONS.ADMIN"
                    (click)="openExerciseTeamResetDialog(team)"
                    [disabled]="
                      !team.isResettable ||
                      exerciseStatusInfo.isResetInProgress ||
                      resettingTeams.includes(team.teamId)
                    "
                    data-testid="reset-exercise-team-data-btn"
                  >
                    @if (
                      !teamResetSteps.has(team.teamId) || !resettingTeams.includes(team.teamId)
                    ) {
                      <span> Reset <i class="fas fa-undo-alt" aria-hidden="true"></i> </span>
                    }
                    @if (resettingTeams.includes(team.teamId) && teamResetSteps.has(team.teamId)) {
                      <span>
                        Resetting | step
                        {{ teamResetSteps.get(team.teamId).stepsCompleted || 0 }} out of
                        {{ teamResetSteps.get(team.teamId).totalSteps || MAX_STEPS }}
                      </span>
                    }
                  </button>
                </div>
                <p class="clear"></p>
              </div>
              <div>{{ team.username }}</div>
            </mat-card>
          }
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel
        [expanded]="openedPanelIndex === 1"
        [hideToggle]="openedPanelIndex === 1"
        (opened)="setOpenedPanel(1)"
      >
        <mat-expansion-panel-header collapsedHeight="120px" expandedHeight="120px">
          <div class="header-text-container">
            <span class="queue-size">{{ data.queueUsers.length }}</span> user{{
              data.queueUsers.length !== 1 ? 's' : ''
            }}
            in queue
          </div>
        </mat-expansion-panel-header>
        <div class="users-list">
          @if (data.queueUsers.length === 0) {
            <cybexer-no-data></cybexer-no-data>
          }
          @for (queueUser of data.queueUsers; track queueUser.username) {
            <mat-card class="user-card">
              <div>{{ queueUser.username }}</div>
              <div class="timestamp-container">
                Since <span class="timestamp">{{ queueUser.timestamp | isaDate: 'medium' }}</span>
              </div>
            </mat-card>
          }
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
}
@if (loading) {
  <cybexer-progress-spinner></cybexer-progress-spinner>
}
