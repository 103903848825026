<div class="task-report-confirm-dialog">
  <h2 matDialogTitle>Task Report</h2>
  @if (!loading) {
    <form [formGroup]="taskConfirmationReportForm">
      <mat-dialog-content class="task-report-confirm-dialog-content">
        <div class="isa-details-box">
          <div class="row score-percentage">
            <span class="label">Score</span>
            @if (hasPermissionToConfirmOrDeny) {
              <mat-slider
                [disabled]="
                  !isExerciseRunning ||
                  taskReportsData.status !== CONFIRMATION_STATUS.PENDING_CONFIRMATION
                "
                #ngSlider
              >
                <input
                  matSliderThumb
                  [value]="scorePercentageControl.value"
                  (input)="
                    scorePercentageControl.setValue(
                      { source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value }.value
                    )
                  "
                  #ngSliderThumb="matSliderThumb"
                />
              </mat-slider>
            }
            <br />
            <span> {{ score }} / {{ taskReportsData.initialScore }} </span>
          </div>
          <div class="row">
            <span class="label">Answer</span>
            <quill-editor
              [ngModel]="taskReportDetails?.answer"
              [ngModelOptions]="{ standalone: true }"
              [readOnly]="true"
              [modules]="{ toolbar: false }"
              [preserveWhitespace]="true"
            ></quill-editor>
          </div>
          <div class="row">
            <span class="label">Time</span>
            {{ taskReportDetails?.timestamp | isaDate: 'medium' }}
          </div>
          <div class="row">
            <span class="label">Title</span>
            {{ taskReportsData.title }}
          </div>
          <div class="row">
            <span class="label">Category</span>
            {{ taskReportsData.category }}
          </div>
          <div class="row">
            <span class="label">Team</span>
            {{ taskReportDetails?.teamId | isaTeamName }}
          </div>
        </div>
        @if (showFeedback()) {
          <mat-form-field class="full-width textarea feedback">
            <mat-label>Feedback</mat-label>
            <textarea
              formControlName="feedback"
              matInput
              [cdkTextareaAutosize]
              tabindex="-1"
            ></textarea>
          </mat-form-field>
        }
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        @if (
          hasPermissionToConfirmOrDeny &&
          taskReportsData?.status === CONFIRMATION_STATUS.PENDING_CONFIRMATION
        ) {
          <cybexer-confirm-action [disabled]="!isExerciseRunning">
            <button
              class="isa-button-confirm"
              mat-raised-button
              color="primary"
              (click)="onSubmit(CONFIRMATION_STATUS.CONFIRMED)"
              [disabled]="!isExerciseRunning || submitted"
            >
              <i class="fas fa-check-circle mat-button-fa" aria-hidden="true"></i>Approve
            </button>
          </cybexer-confirm-action>
          <cybexer-confirm-action [disabled]="!isExerciseRunning">
            <button
              class="isa-button-deny"
              mat-raised-button
              (click)="onSubmit(CONFIRMATION_STATUS.DENIED)"
              [disabled]="!isExerciseRunning || submitted"
            >
              <i class="fas fa-exclamation-circle mat-button-fa" aria-hidden="true"></i>Reject
            </button>
          </cybexer-confirm-action>
        }
        <button mat-button matDialogClose>Cancel</button>
      </mat-dialog-actions>
    </form>
  }
  @if (loading) {
    <cybexer-progress-spinner></cybexer-progress-spinner>
  }
</div>
