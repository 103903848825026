import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationsService } from '@cybexer/ngx-commons';
import { UsersService } from '../../../../services';

@Component({
  selector: 'isa-user-delete-dialog',
  templateUrl: './user-delete-dialog.component.html',
  styleUrls: ['./user-delete-dialog.component.scss'],
})
export class UserDeleteDialogComponent {
  username: string;
  isAdmin: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private usersService: UsersService,
    private notificationsService: NotificationsService,
    private dialogRef: MatDialogRef<UserDeleteDialogComponent>
  ) {
    this.username = this.data.username;
    this.isAdmin = false; // TODO: Will be fixed in ISA-2722
  }

  confirmDeleteUser() {
    this.usersService.removeUser(this.username).subscribe({
      next: () => {
        this.notificationsService.success('User deleted');
        this.dialogRef.close(true);
      },
      error: () => {
        this.notificationsService.error('Could not delete user');
        this.dialogRef.close(true);
      },
    });
  }

  getDeleteConfirmMessage() {
    if (this.isAdmin) {
      return (
        'You are about to delete a user with admin privileges! \n' +
        'Are you sure you want to delete user ' +
        this.username +
        ' ?'
      );
    }
    return 'Are you sure you want to delete user ' + this.username + ' ?';
  }
}
