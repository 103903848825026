import { BaseModel } from '../shared/base.model';

export class Attack extends BaseModel {
  timestamp: Date;
  objectiveContainerId: string;
  status: string;
  objectiveContainerName: string;

  constructor(data?) {
    super(data);
    if (this.status) {
      this.status = this.status.replace(new RegExp('_', 'g'), ' ');
    }
  }
}
