import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationsService } from '@cybexer/ngx-commons';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { BlueTeam, Exercise, ExerciseType } from '../../../models';
import { TeamNamePipe } from '../../../pipes';
import { ExerciseService, SettingsService, TeamScoringService } from '../../../services';
import { FilterStateModel, FilterStateService } from '../../../shared';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'isa-team-scoring',
  templateUrl: './team-scoring.component.html',
  styleUrls: ['./team-scoring.component.scss'],
})
export class TeamScoringComponent implements OnInit {
  filter$: Observable<Partial<FilterStateModel>>;
  exerciseId: string;
  networkSegmentScores: any;
  teamOrderPreviousState: string[] = [];
  chartLabels: string[] = [];
  teams: BlueTeam[] = [];
  selectedTeamsData: any[] = [];
  loading = true;
  isIndividualAssessment: boolean;
  isCTF: boolean;

  constructor(
    private exerciseService: ExerciseService,
    private teamScoringService: TeamScoringService,
    private settingsService: SettingsService,
    private notificationsService: NotificationsService,
    private teamNamePipe: TeamNamePipe,
    private translate: TranslateService,
    public filterStateService: FilterStateService
  ) {}

  ngOnInit(): void {
    this.filter$ = this.filterStateService.filter$(
      'teams',
      'horizontalView',
      'sliderDate',
      'timePeriod'
    );
    this.exerciseService.activeExercise
      .pipe(
        filter((exercise: Exercise) => !!exercise),
        untilDestroyed(this)
      )
      .subscribe((exercise: Exercise) => {
        this.exerciseId = exercise.id;
        this.isCTF = exercise.type === ExerciseType.CTF;
        this.isIndividualAssessment = exercise.isIndividualAssessment;
        this.teams = exercise.blueTeams;
        this.filterStateService.setFilterIfEmptyOrDefault(
          'teams',
          this.teams.map((team) => team.id)
        );
      });

    this.filter$.pipe(untilDestroyed(this)).subscribe((filter) => {
      this.processData(filter);
    });
  }

  processData({ teams, sliderDate }: Partial<FilterStateModel>): void {
    if (this.exerciseId && sliderDate) {
      this.teamScoringService
        .getScoringData(this.exerciseId, sliderDate.toISOString())
        .pipe(
          tap((data) =>
            data.forEach((it) => (it.teamName = this.teamNamePipe.transform(it.teamId)))
          ),
          untilDestroyed(this)
        )
        .subscribe((data) => {
          this.selectedTeamsData = data.filter((teamData) => teams.includes(teamData.teamId));
          this.updateLabels(this.selectedTeamsData);
          this.notifyTeamChange();
          this.loading = false;
        });
    }
  }

  notifyTeamChange(): void {
    if (this.hasTeamOrderChanged()) {
      this.settingsService
        .getSettings(true)
        .pipe(untilDestroyed(this))
        .subscribe((settings) => {
          if (settings.gamenetSettings.soundOnTeamChangeEnabled) {
            this.playSoundOnTeamChange(settings);
            this.notificationsService.info(this.translate.instant('ui.scoring.teamOrderChanged'));
          }
          this.teamOrderPreviousState = this.chartLabels.slice();
        });
    } else {
      this.teamOrderPreviousState = this.chartLabels.slice();
    }
  }

  private playSoundOnTeamChange(settings): void {
    const selectedSoundId = settings.gamenetSettings.selectedSoundId;
    this.teamScoringService
      .getSoundDownloadUrl(this.exerciseId, selectedSoundId)
      .pipe(untilDestroyed(this))
      .subscribe((downloadUrl) => this.loadAndPlaySound(downloadUrl));
  }

  loadAndPlaySound(downloadUrl: string) {
    const audio = new Audio(downloadUrl);
    audio.load();
    audio.play();
  }

  updateLabels(data): void {
    // clean labels array
    this.chartLabels.splice(0, this.chartLabels.length);
    // fill with new data
    data
      .map((item) => item.teamName.toUpperCase())
      .forEach((teamName, i) => {
        this.chartLabels[i] = teamName;
      });
  }

  hasTeamOrderChanged(): boolean {
    if (
      !!this.teamOrderPreviousState.length &&
      this.teamOrderPreviousState.length === this.chartLabels.length
    ) {
      for (let i = 0; i < this.teamOrderPreviousState.length; i++) {
        if (this.teamOrderPreviousState[i] !== this.chartLabels[i]) {
          return true;
        }
      }
    }
    return false;
  }
}
