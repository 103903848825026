<div class="settings-dialog">
  <h2 matDialogTitle>Settings</h2>
  <mat-dialog-content class="team-settings-dialog-content">
    @if (teamSettings) {
      <form [formGroup]="form">
        <mat-form-field id="name-field">
          <mat-label>Name</mat-label>
          <input autocomplete="off" matInput formControlName="teamName" />
        </mat-form-field>
        <label class="avatar-label" for="avatarInput">
          <div class="avatar-btn">
            <isa-avatar [src]="avatarSrc" [exerciseId]="exerciseId" [teamId]="teamId"></isa-avatar>
          </div>
          @if (avatarFile && error == null) {
            <i class="fas fa-check approved-icon" aria-hidden="true"></i>
          }
          @if (avatarFile == null) {
            <span> Upload an avatar</span>
          }
          @if (avatarFile) {
            <span>{{ avatarFile.name }} ({{ avatarFile.size | isaFileSize }})</span>
          }
        </label>
        <input
          (change)="setFile($event)"
          class="avatarInput"
          id="avatarInput"
          type="file"
          [accept]="getFileInputAcceptAttr()"
        />
        @if (error) {
          <div class="row error-container">
            <mat-error class="disable-uppercase">{{ error }}</mat-error>
          </div>
        }
        <div class="restriction-info">
          <div>Max file size: {{ maxFileSize | isaFileSize: 0 }}</div>
          <div>
            Supported types: <span class="disable-uppercase">{{ getFileInputAcceptAttr() }}</span>
          </div>
        </div>
        <isa-avatar-gallery (onAvatarSelect)="onStaticAvatarSelect($event)"></isa-avatar-gallery>
      </form>
    }
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button data-testid="confirm-btn" mat-raised-button color="primary" (click)="save()">
      Save
    </button>
    <button mat-button matDialogClose>Cancel</button>
  </mat-dialog-actions>
</div>
