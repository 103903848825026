import { BaseModel } from '../shared/base.model';

export class ReportList extends BaseModel {
  timestamp: Date;
  targetId?: string;
  type: string;
  incidentType?: string;
  targetName?: string;

  constructor(data?) {
    super(data);
    this.type = this.type.replace(new RegExp('_', 'g'), ' ');
    if (this.incidentType) {
      this.incidentType = this.incidentType.replace(new RegExp('_', 'g'), ' ');
    }
  }
}
