<div class="exercise-integrations-dialog">
  @if (exercise) {
    <h2 matDialogTitle class="exercise-name">{{ exercise.name }} integration events</h2>
    <mat-dialog-content>
      <!-- This needs rethinking what and where to show exactly-->
      @if (exercise.type !== ExerciseType.UNPUBLISHED) {
        <isa-integration-event-list
          [eventTypes]="[
            IntegrationEventType.EXERCISE_START,
            IntegrationEventType.EXERCISE_END,
            IntegrationEventType.EXERCISE_PROGRESS_UPDATE,
            IntegrationEventType.EXERCISE_TEAM_PROGRESS_UPDATE
          ]"
          [subjectType]="IntegrationEventSubjectType.EXERCISE"
          [subjectId]="exercise.id"
        ></isa-integration-event-list>
      }
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button matDialogClose>Close</button>
    </mat-dialog-actions>
  }
</div>
