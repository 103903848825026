<div class="attacks">
  <mat-card>
    <div widgetHandle class="live-card-title">
      <i class="fas fa-bolt" aria-hidden="true"></i>Red Attacks Live
    </div>
    @if ((attacks.length === 0 && dataLoaded) || !teamId) {
      <div class="no-data">No data to display</div>
    }
    @for (redAttack of attacks; track redAttack) {
      <div class="red-attack-row">
        <div class="timestamp">[{{ redAttack.timestamp | isaDate: 'mediumTime' }}]</div>
        <div class="nowrap">{{ redAttack.status }}</div>
        <div class="truncate">{{ redAttack.objectiveContainerName }}</div>
      </div>
    }
  </mat-card>
</div>
