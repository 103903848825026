import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { NotificationsService } from '@cybexer/ngx-commons';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService, PreferenceService } from '../services';

/**
 * Checks if an exercise is selected.
 */
@Injectable()
export class GamenetExerciseGuard {
  constructor(
    private router: Router,
    private notificationsService: NotificationsService,
    private preferenceService: PreferenceService,
    private authenticationService: AuthenticationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const currentUser = this.authenticationService.currentUser;
    if (currentUser && currentUser.isObserver()) {
      return of(true);
    }

    return this.preferenceService.getPreferences().pipe(
      map((preference) => {
        if (preference.defaultExerciseId) {
          return true;
        } else {
          this.notificationsService.info('Module required', 'Please select module');
          this.router.navigate(['/app/gamenet/exercise']);
          return false;
        }
      })
    );
  }
}
