<div class="target-assignment-dialog">
  <h2 matDialogTitle>Assign target {{ target?.targetName }}</h2>
  <mat-dialog-content>
    <div>
      <mat-form-field class="assign-all">
        <mat-label>Assign all to</mat-label>
        <mat-select
          [ngModel]="assignAllValue"
          (ngModelChange)="assignAll($event)"
          data-testid="assign-all-user-dropdown"
        >
          <mat-option value="">No one</mat-option>
          @for (groupedUser of groupedUsers; track groupedUser) {
            <mat-option [value]="groupedUser.username">
              {{ groupedUser.username }}
              @if (groupedUser.isLocal()) {
                <i
                  class="fas fa-database user-dropdown-origin-icon"
                  aria-hidden="true"
                  matTooltip="LOCAL"
                ></i>
              }
              @if (groupedUser.isFromLDAP()) {
                <i
                  class="fas fa-users user-dropdown-origin-icon"
                  aria-hidden="true"
                  matTooltip="LDAP"
                ></i>
              }
              @if (groupedUser.isMissing) {
                <span class="user-dropdown-missing-label">(missing)</span>
              }
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>

    <div class="target-checks">
      @for (targetCheck of target?.targetChecks; track targetCheck) {
        <div class="target-check">
          <div class="target-check-name">{{ targetCheck.name }}</div>
          <mat-form-field class="assign-target-check-field">
            <mat-label>Assign to</mat-label>
            <mat-select [(ngModel)]="targetCheck.username" (valueChange)="resetAssignAllValue()">
              <mat-option value="">No one</mat-option>
              @for (groupedUser of groupedUsers; track groupedUser) {
                <mat-option [value]="groupedUser.username">
                  {{ groupedUser.username }}
                  @if (groupedUser.isLocal()) {
                    <i
                      class="fas fa-database user-dropdown-origin-icon"
                      aria-hidden="true"
                      matTooltip="LOCAL"
                    ></i>
                  }
                  @if (groupedUser.isFromLDAP()) {
                    <i
                      class="fas fa-users user-dropdown-origin-icon"
                      aria-hidden="true"
                      matTooltip="LDAP"
                    ></i>
                  }
                  @if (groupedUser.isMissing) {
                    <span class="user-dropdown-missing-label">(missing)</span>
                  }
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      }
      @if (availableTargetChecks.length === 0) {
        <cybexer-no-data
          message="No target checks available that are not already assigned to you"
        ></cybexer-no-data>
      }
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div>
      <button (click)="assignTarget()" class="isa-button-confirm" mat-raised-button color="primary">
        Assign
      </button>
      <button mat-button matDialogClose>Cancel</button>
    </div>
  </mat-dialog-actions>
</div>
