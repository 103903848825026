<div class="target-revert-dialog">
  <h2 matDialogTitle>Revert target {{ target.targetName }}</h2>
  <form [formGroup]="form" (ngSubmit)="revertTarget(target.vmId, target.targetName, teamId)">
    <mat-dialog-content class="target-revert-dialog-content">
      <div class="disable-uppercase dialog-content-txt">
        Are you sure you want to revert target to current snapshot?
      </div>
      <div class="disable-uppercase dialog-content-txt">
        Revert action costs penalty
        <b>{{ target.targetRevertPenalty }} points</b>
      </div>
      <div class="disable-uppercase dialog-content-txt">
        Confirm revert action by writing "revert"
      </div>
      <div class="isa-mat-form-row">
        <mat-form-field class="full-width">
          <input
            autocomplete="off"
            matInput
            required="true"
            type="text"
            formControlName="confirmText"
          />
          @if (form.controls['confirmText'].hasError('required')) {
            <mat-error> Please write "revert" for final confirmation </mat-error>
          }

          @if (
            form.controls['confirmText'].invalid &&
            form.controls['confirmText'].dirty &&
            form.controls['confirmText'].hasError('invalidConfirm')
          ) {
            <mat-error>
              {{ form.get('confirmText').errors['invalidConfirm'] }}
            </mat-error>
          }
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <div>
        <button
          type="submit"
          data-testid="confirm-btn"
          mat-raised-button
          color="primary"
          [disabled]="revertInProgress"
        >
          Revert
        </button>
        <button mat-button matDialogClose data-testid="cancel-btn">Cancel</button>
      </div>
    </mat-dialog-actions>
  </form>
</div>
