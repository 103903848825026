import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TooltipItem } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { Score, TeamScoringData } from '../../../../models';
import { PreferenceService } from '../../../../services';
import { FilterStateModel, FilterStateService } from '../../../../shared';
import {
  BAR_CHART_OPTIONS,
  CTF_SCORE_TYPES_ORDER,
  ChartBackgroundColorConfig,
  ChartColorConfigurator,
  HORIZONTAL_STACKED_CHART_OPTIONS,
  SCORE_TYPES_ORDER,
  TOTAL_SCORE_CHART_COLOR,
  VERTICAL_STACKED_CHART_OPTIONS,
} from '../chart-default-settings';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'isa-scoring-charts',
  templateUrl: './scoring-charts.component.html',
  styleUrls: ['./scoring-charts.component.scss'],
})
export class ScoringChartsComponent implements OnInit {
  @Input() isCTF: boolean;
  @ViewChild('horizontalStackedChart') horizontalStackedChart: BaseChartDirective;
  @ViewChild('verticalStackedChart') verticalStackedChart: BaseChartDirective;
  @ViewChild('totalScoreChart') totalScoreChart: BaseChartDirective;

  filter$: Observable<Partial<FilterStateModel>>;

  networkSegmentScores: any;
  barChartData: any[] = [
    {
      data: [],
      label: 'SCORE',
      maxBarThickness: 300,
      backgroundColor: TOTAL_SCORE_CHART_COLOR[0].backgroundColor,
    },
  ];
  stackedChartData: any[] = [];
  chartLabels: string[] = [];
  stackedChartColors: ChartBackgroundColorConfig[] = [];
  verticalStackedChartOptions;
  horizontalStackedChartOptions;
  barChartOptions;
  firstLoad = true;

  private availabilityIndex: number;
  private _scoringData = new BehaviorSubject<any[]>(null);

  @Input()
  set scoringData(value) {
    this._scoringData.next(value);
  }

  get scoringData() {
    return this._scoringData.getValue();
  }

  constructor(
    private translate: TranslateService,
    private preferenceService: PreferenceService,
    public filterStateService: FilterStateService
  ) {}

  ngOnInit() {
    this.filter$ = this.filterStateService.filter$('horizontalView');
    this.preferenceService
      .getPreferences()
      .pipe(
        first(),
        tap(() => this._scoringData.subscribe(() => this.processData()))
      )
      .subscribe((pref) => {
        this.verticalStackedChartOptions = VERTICAL_STACKED_CHART_OPTIONS(pref.isLightTheme);
        this.horizontalStackedChartOptions = HORIZONTAL_STACKED_CHART_OPTIONS(pref.isLightTheme);
        this.barChartOptions = BAR_CHART_OPTIONS(pref.isLightTheme);
      });
    this.translate.onLangChange.subscribe(() => {
      this.firstLoad = true;
      this.processData();
    });
  }

  processData(): void {
    if (this.scoringData) {
      this.updateLabels(this.scoringData);
      this.updateStackedChartData(this.scoringData);
      this.updateBarChartScores(this.scoringData);
      if (this.availabilityIndex >= 0) {
        this.updateNetworkSegmentScores(this.scoringData);
      }
      this.updateCharts();
      this.setChartTooltipOptions();
    }
  }

  updateLabels(data): void {
    const labels = [];
    if (Object.prototype.hasOwnProperty.call(data[0], 'username')) {
      data
        .map((item) => item.username.toUpperCase())
        .forEach((username, i) => {
          labels[i] = username;
        });
    } else {
      data
        .map((item) => item.teamName.toUpperCase())
        .forEach((teamName, i) => {
          labels[i] = teamName;
        });
    }
    this.chartLabels = labels;
  }

  updateStackedChartData(data): void {
    if (this.firstLoad) {
      this.stackedChartColors = [];
      this.stackedChartData = data
        .reduce((datasets, item, i) => {
          item.scores.forEach((score) => {
            const label = this.translate.instant('ui.' + score.category.toLowerCase());
            // Preserve both category and label
            const dataset = datasets.find((s) => s.category === score.category);

            if (!dataset) {
              const datasetValues = [];
              datasetValues[i] = score.value;
              datasets = datasets.concat([
                {
                  data: datasetValues,
                  label: label,
                  category: score.category, // Add category
                  maxBarThickness: 300,
                },
              ]);
            } else {
              dataset.data[i] = score.value;
            }
          });

          // Ensure the value is 0 when the category's score for a team is not provided
          datasets.forEach((dataset) => {
            if (!item.scores.find((score) => score.category === dataset.category)) {
              dataset.data[i] = 0;
            }
          });
          return datasets;
        }, [])
        .sort((a, b) => {
          return this.isCTF
            ? CTF_SCORE_TYPES_ORDER.indexOf(a.label) - CTF_SCORE_TYPES_ORDER.indexOf(b.label)
            : SCORE_TYPES_ORDER.indexOf(a.label) - SCORE_TYPES_ORDER.indexOf(b.label);
        });

      this.availabilityIndex = this.stackedChartData.findIndex(
        (dataset) => dataset.category === 'AVAILABILITY'
      );

      this.stackedChartData.forEach((score) => {
        const colorConfig = ChartColorConfigurator.createBackgroundColorConfig(
          score.category,
          this.isCTF
        );

        score.backgroundColor = colorConfig.backgroundColor;
        this.stackedChartColors.push(colorConfig);
      });

      this.firstLoad = false;
    } else {
      data.forEach((item: TeamScoringData, index) => {
        item.scores.forEach((score: Score) => {
          this.stackedChartData.find((dataset) => dataset.category === score.category).data[index] =
            score.value;
        });
      });
    }
  }

  updateBarChartScores(data): void {
    data
      .reduce((acc, item) => {
        return acc.concat(
          item.scores.reduce(
            (totalScore, score) => {
              // parseFloat() and toFixed() are used to avoid problems with binary floating points
              totalScore[0] = parseFloat((totalScore[0] + score.value).toFixed(2));
              return totalScore;
            },
            [0]
          )
        );
      }, [])
      .forEach((score, i) => {
        this.barChartData[0].data[i] = +score.toFixed(2);
      });
  }

  updateNetworkSegmentScores(data): void {
    this.networkSegmentScores = [];
    data.map((item) => {
      const networkSegmentScores = item.scores.filter(
        (score) => score.category === 'AVAILABILITY'
      )[0]?.networkSegmentScores;
      if (networkSegmentScores) {
        this.networkSegmentScores.push(networkSegmentScores);
      }
    });
  }

  setChartTooltipOptions(): void {
    if (
      this.verticalStackedChartOptions &&
      Object.keys(this.verticalStackedChartOptions.plugins.tooltip).length !== 0
    ) {
      return;
    }
    if (this.verticalStackedChartOptions) {
      this.verticalStackedChartOptions.plugins.tooltip =
        this.horizontalStackedChartOptions.plugins.tooltip = {
          mode: 'point',
          axis: 'y',
          callbacks: {
            afterBody: (tooltipItem: TooltipItem<'bar'>) => {
              if (
                tooltipItem[0].datasetIndex === this.availabilityIndex &&
                this.networkSegmentScores[tooltipItem[0].index]
              ) {
                return this.networkSegmentScores[tooltipItem[0].index].map(
                  (item) => item.name + ': ' + item.value
                );
              }
            },
          },
        };
    }
  }

  updateCharts(): void {
    if (
      this.horizontalStackedChart &&
      this.horizontalStackedChart.chart &&
      this.horizontalStackedChart.chart.config
    ) {
      this.updateChartColors(this.horizontalStackedChart);
      this.horizontalStackedChart.chart.update();
    }
    if (
      this.verticalStackedChart &&
      this.verticalStackedChart.chart &&
      this.verticalStackedChart.chart.config
    ) {
      this.updateChartColors(this.verticalStackedChart);
      this.verticalStackedChart.chart.update();
    }
    if (this.totalScoreChart && this.totalScoreChart.chart) {
      this.totalScoreChart.chart.update();
    }
  }

  get chartHeight() {
    const baseHeight = 80;
    const barHeight = 24;
    return this.chartLabels.length * barHeight + baseHeight;
  }

  private updateChartColors(chart: BaseChartDirective) {
    chart.chart.config.data.datasets.forEach((dataset: any, index: number) => {
      dataset.backgroundColor = this.stackedChartColors[index].backgroundColor;
    });
  }
}
