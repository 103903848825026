import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { NgxCommonsModule } from '@cybexer/ngx-commons';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import * as Sentry from '@sentry/angular-ivy';
import { ThemeService } from 'ng2-charts';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslationService } from './services/shared/translation.service';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { lastValueFrom } from 'rxjs';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppErrorHandler } from './app.error-handler';
import { AppRoutingModule } from './app.routing';
import { ErrorPageComponent } from './error-page';
import { UnAuthorizedInterceptorServiceInterceptor } from './interceptors/unauthorized-inteceptor';
import { BaseLayoutModule, IntroLayoutModule } from './layout';
import {
  AuthGuard,
  GamenetExerciseGuard,
  GamenetModuleGuard,
  ObserverAuthGuard,
  ObserverGuard,
  PermissionGuard,
} from './resolvers';
import { ObserverKeyDialogComponent } from './resolvers/observer-key-dialog/observer-key-dialog.component';
import {
  AdvancedScoringLogService,
  AttackCampaignService,
  AttackReportsService,
  AuthenticationService,
  CustomVariableService,
  ExerciseService,
  FileService,
  GmaService,
  HealthCheckDataService,
  IncidentReportsService,
  IncidentService,
  IncidentTypeService,
  IntegrationsService,
  IntervalService,
  MitreIntegrationService,
  NewsInjectService,
  PreferenceService,
  ProcessStatusService,
  ProductKeyService,
  ReportingStatusService,
  RoleService,
  ScoringLogService,
  SettingsService,
  SituationReportsService,
  SituationService,
  SoundService,
  SpecialScoringService,
  StatusSummaryService,
  SummaryTimelineService,
  SystemService,
  TargetCheckAvailabilityService,
  TargetService,
  TargetStatusService,
  TaskDetailsService,
  TaskReportsService,
  TeamScoringService,
  TeamService,
  TeamTimelineService,
  UserScoringService,
  UsersService,
} from './services';
import { IsaNotificationService } from './services/gamenet/isa-notification.service';
import { FilterState, SharedModule } from './shared';
import { setAppInjector } from './shared/app-injector';
import { ViewsModule } from './views/views.module';
import { MarkdownModule } from 'ngx-markdown';
import { WindowRefService } from './services/shared/window-ref.service';

const APP_PROVIDERS = [
  SystemService,
  AuthenticationService,
  ExerciseService,
  TeamService,
  PreferenceService,
  SettingsService,
  UsersService,
  RoleService,
  ProductKeyService,
  TargetService,
  StatusSummaryService,
  IncidentTypeService,
  IncidentService,
  SituationService,
  AttackCampaignService,
  IntervalService,
  TargetStatusService,
  IntervalService,
  AttackReportsService,
  IncidentReportsService,
  SituationReportsService,
  SpecialScoringService,
  AdvancedScoringLogService,
  TeamScoringService,
  UserScoringService,
  ScoringLogService,
  SummaryTimelineService,
  TeamTimelineService,
  ReportingStatusService,
  SoundService,
  FileService,
  IntegrationsService,
  GmaService,
  HealthCheckDataService,
  TargetCheckAvailabilityService,
  TaskReportsService,
  MitreIntegrationService,
  NewsInjectService,
  TaskDetailsService,
  IsaNotificationService,
  ProcessStatusService,
  CustomVariableService,
  WindowRefService,
];

const appInit = (settingsService: SettingsService) => {
  return () => {
    lastValueFrom(settingsService.getSentrySettings()).then((data) => {
      Sentry.init({
        dsn: data.dsn,
        integrations: [new Sentry.BrowserTracing()],
        ignoreErrors: ['Non-Error exception captured', 'ResizeObserver loop limit exceeded'],
        tracesSampleRate: 0.2,
        environment: data.environment,
        release: data.version,
        beforeBreadcrumb(breadcrumb) {
          const unwantedBreadcrumbCategories = ['xhr', 'console', 'fetch'];
          return unwantedBreadcrumbCategories.includes(breadcrumb.category) ? null : breadcrumb;
        },
        beforeSend(event) {
          return environment.production ? event : null;
        },
        enabled: data.dsn != null,
      });
    });
  };
};

@NgModule({
  declarations: [AppComponent, ErrorPageComponent, ObserverKeyDialogComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ViewsModule,
    IntroLayoutModule,
    BaseLayoutModule,
    SharedModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useClass: TranslationService,
      },
    }),
    NgxGoogleAnalyticsModule.forRoot(environment.gaMeasurementId),
    NgxGoogleAnalyticsRouterModule,
    NgxCommonsModule,
    NgxsDispatchPluginModule.forRoot(),
    MarkdownModule.forRoot(),
    NgxsModule.forRoot([FilterState], {
      developmentMode: !environment.production,
      selectorOptions: {
        // These Selector Settings are recommended in preparation for NGXS v4
        suppressErrors: false,
        injectContainerState: false,
      },
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
  ],
  providers: [
    APP_PROVIDERS,
    AuthGuard,
    ObserverAuthGuard,
    ObserverGuard,
    PermissionGuard,
    GamenetExerciseGuard,
    GamenetModuleGuard,
    SettingsService,
    ThemeService,
    TranslatePipe,
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnAuthorizedInterceptorServiceInterceptor,
      multi: true,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      deps: [SettingsService, Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    setAppInjector(injector);
  }
}
