import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, Input } from '@angular/core';
import { ExerciseOverview, ExerciseStatus, ExerciseType } from '../../../../models';
import { DomainPermission, EXERCISE_PERMISSIONS } from '../../../../shared';
import { ExerciseResetDialogComponent } from '../exercise-reset-dialog/exercise-reset-dialog.component';
import { ExerciseDeleteDialogComponent } from '../exercise-delete-dialog/exercise-delete-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService, ExerciseService, ProcessStatusService } from '../../../../services';
import { ExerciseConfirmDialogComponent } from '../exercise-confirm-dialog/exercise-confirm-dialog.component';
import { ExerciseImageDialogComponent } from '../exercise-image-dialog/exercise-image-dialog.component';
import { ExerciseDialogComponent } from '../exercise-dialog/exercise-dialog.component';
import { ExerciseIntegrationsDialogComponent } from '../exercise-integrations-dialog/exercise-integrations-dialog.component';
import { Subscription } from 'rxjs';
import { ExerciseResetSteps } from '../../../../models/gamenet/exercise.model';
import { ProcessStatus } from '../../../../models/gamenet/process-status.model';
import { finalize } from 'rxjs/operators';
import { TeamSettingsDialogComponent } from '../team-settings-dialog/team-settings-dialog.component';
import { NotificationsService } from '@cybexer/ngx-commons';

@UntilDestroy()
@Component({
  selector: 'isa-exercise-card',
  templateUrl: './exercise-card.component.html',
  styleUrls: ['./exercise-card.component.scss'],
})
export class ExerciseCardComponent {
  @Input() exercise: ExerciseOverview;
  @Input() isSelected: boolean;
  @Input() isInGroup: boolean;
  @Input() findExerciseOverviewById: (id: string) => ExerciseOverview;

  exerciseResetSteps: Map<String, ExerciseResetSteps> = new Map();
  exerciseConfirmDialogRef: MatDialogRef<ExerciseConfirmDialogComponent>;
  exerciseImageDialogRef: MatDialogRef<ExerciseImageDialogComponent>;
  exerciseDialogRef: MatDialogRef<ExerciseDialogComponent>;
  exerciseDeleteDialogRef: MatDialogRef<ExerciseDeleteDialogComponent, boolean>;
  exerciseResetDialogRef: MatDialogRef<ExerciseResetDialogComponent, boolean>;
  exerciseIntegrationsDialogRef: MatDialogRef<ExerciseIntegrationsDialogComponent, boolean>;
  exerciseResetStatusSubscriptions: Map<String, Subscription> = new Map();
  showResetSteps = false;
  isProcessing = false;

  readonly MAX_STEPS = 7;
  protected readonly EXERCISE_PERMISSIONS = EXERCISE_PERMISSIONS;
  protected readonly ExerciseType = ExerciseType;
  protected readonly ExerciseStatus = ExerciseStatus;

  constructor(
    private dialog: MatDialog,
    private exerciseService: ExerciseService,
    private processStatusService: ProcessStatusService,
    private notificationsService: NotificationsService,
    private authenticationService: AuthenticationService
  ) {}

  reloadScripts(exerciseId: string): void {
    this.isProcessing = true;
    this.exerciseService
      .reloadScripts(exerciseId)
      .pipe(finalize(() => (this.isProcessing = false)))
      .subscribe(() => {
        this.notificationsService.success('Scripts have been reloaded');
      });
  }

  openExerciseResetDialog(exercise: ExerciseOverview): void {
    this.exerciseResetDialogRef = this.dialog.open(ExerciseResetDialogComponent, {
      disableClose: false,
      data: { exercise },
    });

    this.listenForResetProcessUpdates(exercise);
    this.exerciseResetDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const filteredExerciseOverview = this.findExerciseOverviewById(exercise.id);
        filteredExerciseOverview && (filteredExerciseOverview.isResetInProgress = true);
      } else {
        this.clearResetData(exercise.id);
      }
    });
  }

  openExerciseIntegrationsDialog(exercise: ExerciseOverview): void {
    this.exerciseIntegrationsDialogRef = this.dialog.open(ExerciseIntegrationsDialogComponent, {
      disableClose: false,
      data: { exercise },
    });
  }

  openExerciseDialog(exercise: ExerciseOverview): void {
    this.exerciseDialogRef = this.dialog.open(ExerciseDialogComponent, {
      disableClose: false,
      data: { exercise },
    });
  }

  openExerciseImageDialog(exercise: ExerciseOverview): void {
    this.exerciseImageDialogRef = this.dialog.open(ExerciseImageDialogComponent, {
      disableClose: false,
      data: {
        exerciseId: exercise.id,
      },
    });
  }

  openExerciseConfirmDialog(exercise: ExerciseOverview, eventType: string): void {
    this.exerciseConfirmDialogRef = this.dialog.open(ExerciseConfirmDialogComponent, {
      disableClose: false,
      data: {
        exercise,
        eventType,
      },
    });

    this.exerciseConfirmDialogRef.afterClosed().subscribe((type: string) => {
      if (type === 'STARTEX') {
        exercise.status = 'RUNNING';
      }
      if (type === 'ENDEX') {
        exercise.status = 'STOPPED';
      }
    });
  }

  openTeamSettings(exercise: ExerciseOverview) {
    this.exerciseService.getUserBlueTeam(exercise.id).subscribe((teamId) => {
      this.dialog.open(TeamSettingsDialogComponent, {
        disableClose: false,
        data: {
          exerciseId: exercise.id,
          teamId: teamId,
        },
      });
    });
  }

  isBlueTeam(exerciseId: string) {
    return this.authenticationService.hasRole(exerciseId, 'BLUE');
  }

  selectExercise(exerciseId) {
    this.exerciseService.selectExercise(exerciseId);
  }

  openExerciseDeleteDialog(exercise: ExerciseOverview): void {
    this.exerciseDeleteDialogRef = this.dialog.open(ExerciseDeleteDialogComponent, {
      disableClose: false,
      data: { exercise },
    });

    this.exerciseDeleteDialogRef.afterClosed().subscribe((isDeleted) => {});
  }

  getSystemEventsPermissions(exerciseId: string): string[] {
    return [new DomainPermission(EXERCISE_PERMISSIONS.ADMIN).withTargets(exerciseId)].map((it) =>
      it.toString()
    );
  }

  private clearResetData(exerciseId: string) {
    this.exerciseResetSteps.delete(exerciseId);
    this.unsubscribeFromStatusEvents(exerciseId);
  }

  private unsubscribeFromStatusEvents(exerciseId: string) {
    if (this.exerciseResetStatusSubscriptions.has(exerciseId)) {
      this.exerciseResetStatusSubscriptions.get(exerciseId).unsubscribe();
      this.exerciseResetStatusSubscriptions.delete(exerciseId);
    }
  }

  private listenForResetProcessUpdates(exercise: ExerciseOverview) {
    this.unsubscribeFromStatusEvents(exercise.id);

    // return if already listening to this exercise reset process updates, we know this because of reset steps map
    if (this.exerciseResetSteps.get(exercise.id)) return;
    this.showResetSteps = true;
    const subscription = this.processStatusService
      .listenForProcessStatusUpdateEvent(exercise.id)
      .pipe(untilDestroyed(this))
      .subscribe((data) => this.appendResetStep(exercise, data));
    this.exerciseResetStatusSubscriptions.set(exercise.id, subscription);
  }

  private appendResetStep(exercise: ExerciseOverview, data: ProcessStatus) {
    if (data.secondaryTargetObjectId != null) return;

    const targetExercise = this.exerciseResetSteps.get(exercise.id);
    if (data.targetObjectId === exercise.id) {
      if (targetExercise && targetExercise.stepsCompleted !== data.totalSteps) {
        targetExercise.stepsCompleted++;
        targetExercise.totalSteps = data.totalSteps;
      } else {
        this.exerciseResetSteps.set(exercise.id, new ExerciseResetSteps(1, data.totalSteps));
      }
    }
  }
}
