import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, StateToken } from '@ngxs/store';
import {
  ResetFilter,
  SetFilterProperties,
  SetFilterProperty,
  SetFilterPropertyIfEmptyOrDefault,
} from './filter.actions';

export const FILTER_STATE_TOKEN = new StateToken<FilterStateModel>('filter');

export type FilterStateProperty = keyof FilterStateModel;

export type FilterStateValue = string | string[] | Date | boolean | number | null;

export interface FilterStateModel {
  user: string;
  team: string;
  teams: string[];
  scoreCategories: string[];
  objectiveCategories: string[];
  tasks: string[];
  campaignPhase: string;
  networkSegment: string;
  targetName: string;
  targetStatuses: string[];
  injectStatus: string;
  startDate: Date;
  endDate: Date;
  exerciseDurationEndDate: Date;
  sliderDate: Date;
  timePeriod: number;
  assignedToMe: boolean;
  horizontalView: boolean;
  highlightAssignments: boolean;
  pendingConfirmationOnly: boolean;
  compactView: boolean;
}

const DEFAULT_STATE: FilterStateModel = {
  user: null,
  team: null,
  teams: [],
  scoreCategories: [],
  objectiveCategories: [],
  tasks: [],
  campaignPhase: null,
  networkSegment: null,
  targetName: null,
  targetStatuses: [],
  injectStatus: null,
  startDate: null,
  endDate: null,
  exerciseDurationEndDate: null,
  sliderDate: null,
  timePeriod: null,
  assignedToMe: false,
  horizontalView: false,
  highlightAssignments: false,
  pendingConfirmationOnly: true,
  compactView: false,
};

@State<FilterStateModel>({
  name: FILTER_STATE_TOKEN,
  defaults: DEFAULT_STATE,
})
@Injectable()
export class FilterState {
  @Action(SetFilterProperty)
  setProperty({ patchState }: StateContext<FilterStateModel>, { name, value }: SetFilterProperty) {
    patchState({ [name]: value });
  }

  @Action(SetFilterProperties)
  setProperties({ patchState }: StateContext<FilterStateModel>, { filter }: SetFilterProperties) {
    patchState(filter);
  }

  @Action(SetFilterPropertyIfEmptyOrDefault)
  setPropertyIfEmptyOrDefault(
    { patchState, getState }: StateContext<FilterStateModel>,
    { name, value }: SetFilterProperty
  ) {
    const currentValue = getState()[name];
    if (currentValue == null || currentValue === DEFAULT_STATE[name]) {
      patchState({ [name]: value });
    }
  }

  @Action(ResetFilter)
  resetFilter({ setState }: StateContext<FilterStateModel>) {
    setState(DEFAULT_STATE);
  }

  @Selector([FILTER_STATE_TOKEN])
  static filter(state: FilterStateModel): FilterStateModel {
    return state;
  }

  static slice(...properties: FilterStateProperty[]) {
    return createSelector(
      properties.map((property) => this.propertySelector(property)),
      (...state: FilterStateValue[]): Partial<FilterStateModel> => {
        return Object.assign({}, ...properties.map((property, i) => ({ [property]: state[i] })));
      }
    );
  }

  static propertySelector(filterProperty: FilterStateProperty) {
    return createSelector([FilterState], (state: FilterStateModel): FilterStateValue => {
      return state[filterProperty];
    });
  }
}
