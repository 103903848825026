import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from '@cybexer/ngx-commons';
import { finalize } from 'rxjs/operators';
import { ExerciseService } from '../../../../services';

@Component({
  selector: 'isa-exercise-image-dialog',
  templateUrl: './exercise-image-dialog.component.html',
  styleUrls: ['./exercise-image-dialog.component.scss'],
})
export class ExerciseImageDialogComponent implements OnInit {
  private static ACCEPTED_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif'];
  private static MAX_FILE_SIZE = 1_000_000; // 1MB
  private static ERR_UNSUPPORTED_FILE_TYPE = 'Unsupported file type';
  private static ERR_EXCEEDED_FILE_SIZE = 'File size exceeds the limit';

  exerciseId: string;
  loading = false;
  imageFile?: File;
  imageSrc: any;
  error: String;
  currentImageLoaded = false;
  currentImageSrcUrl: string;
  isError = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private exerciseService: ExerciseService,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit() {
    this.exerciseId = this.data['exerciseId'];
    this.loadCurrentExerciseImage();
  }

  private loadCurrentExerciseImage() {
    this.exerciseService.getExerciseImage(this.exerciseId).subscribe({
      next: (imageUrl) => {
        this.currentImageSrcUrl = imageUrl;
      },
      error: () => this.setError(),
    });
  }

  getFileInputAcceptAttr() {
    return ExerciseImageDialogComponent.ACCEPTED_EXTENSIONS.join(',');
  }

  get maxFileSize(): number {
    return ExerciseImageDialogComponent.MAX_FILE_SIZE;
  }

  setFile(event): void {
    if (event.target.files.length) {
      this.imageFile = event.target.files[0];
      this.validate();
      if (!this.error) {
        this.readFile();
      }
    }
  }

  private readFile() {
    if (FileReader && this.imageFile) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        this.imageSrc = fileReader.result;
      };
      fileReader.readAsDataURL(this.imageFile);
    }
  }

  private validate(): void {
    if (this.imageFile.type.indexOf('image') === -1) {
      this.error = ExerciseImageDialogComponent.ERR_UNSUPPORTED_FILE_TYPE;
      return;
    }

    const filename = this.imageFile.name;
    const extension = filename.substring(filename.lastIndexOf('.') + 1, filename.length);
    if (!ExerciseImageDialogComponent.ACCEPTED_EXTENSIONS.includes(extension.toLowerCase())) {
      this.error = ExerciseImageDialogComponent.ERR_UNSUPPORTED_FILE_TYPE;
    } else if (this.imageFile.size > ExerciseImageDialogComponent.MAX_FILE_SIZE) {
      this.error = ExerciseImageDialogComponent.ERR_EXCEEDED_FILE_SIZE;
    } else {
      this.error = null;
    }
  }

  uploadImage(): void {
    if (this.imageFile == null) {
      this.error = 'Please select file';
    }
    if (this.error) return;

    this.loading = true;

    this.exerciseService
      .uploadImage(this.exerciseId, this.imageFile)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(() => {
        this.imageFile = null;
        this.imageSrc = null;
        this.loadCurrentExerciseImage();
        this.notificationsService.success('Exercise image has been uploaded');
      });
  }

  completeLoading() {
    this.currentImageLoaded = true;
  }

  setError() {
    this.isError = true;
  }

  deleteCurrentImage() {
    this.exerciseService.deleteExerciseImage(this.exerciseId).subscribe(() => {
      this.currentImageSrcUrl = null;
      this.currentImageLoaded = false;
      this.isError = false;
    });
  }
}
