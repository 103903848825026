<div class="exercise-delete-dialog">
  <h2 matDialogTitle>{{ exercise.name }}</h2>
  <mat-dialog-content class="exercise-delete-dialog-content">
    Are you sure you want to delete the exercise?
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div>
      <button
        data-testid="confirm-btn"
        mat-raised-button
        color="primary"
        (click)="deleteExercise(exercise.id)"
      >
        Delete
      </button>
      <button mat-button matDialogClose>Cancel</button>
    </div>
  </mat-dialog-actions>
</div>
