<div class="report-list">
  <mat-card>
    <div widgetHandle class="live-card-title">
      <i class="far fa-comment" aria-hidden="true"></i>Reports live
    </div>
    @if ((reportList.length === 0 && dataLoaded) || !teamId) {
      <div class="no-data">No data to display</div>
    }
    @for (report of reportList; track report) {
      <div class="report-row">
        <div class="timestamp">[{{ report.timestamp | isaDate: 'mediumTime' }}]</div>
        <div>
          @if (report.type === 'SITUATION REPORT') {
            <span class="nowrap">{{ report.type }}</span>
          }
          @if (report.incidentType) {
            <span class="nowrap">{{ report.incidentType }}</span>
          }
        </div>
        <div class="truncate">
          <span>{{ report.targetName }}</span>
        </div>
      </div>
    }
  </mat-card>
</div>
