<cybexer-card
  class="exercise"
  [selected]="isSelected"
  [ngClass]="isInGroup ? 'grouped-exercise' : ''"
>
  <mat-card-title>{{ exercise.name }}</mat-card-title>
  <mat-card-subtitle>
    <cybexer-row>
      <cybexer-meta-item>{{ exercise.type }}</cybexer-meta-item>
      @if (exercise.duration) {
        <cybexer-meta-item [icon]="'fa fa-stopwatch'">
          {{ exercise.duration | isaDuration }}
        </cybexer-meta-item>
      }
      @if (exercise.tasksCount) {
        <cybexer-meta-item [icon]="'fa fa-layer-group'">
          {{ exercise.tasksCount }} tasks
        </cybexer-meta-item>
      }
      @if (exercise.niceIds && exercise.niceIdsCount) {
        <cybexer-meta-item [icon]="'fa fa-check'">
          {{ exercise.niceIdsCount }} nice items
        </cybexer-meta-item>
      }
      @if (exercise.mitreTags && exercise.mitreTagsCount) {
        <cybexer-meta-item [icon]="'fa fa-check'">
          {{ exercise.mitreTagsCount }} mitre att&ck items
        </cybexer-meta-item>
      }
    </cybexer-row>
  </mat-card-subtitle>
  <mat-card-content>
    <div class="exercise-status">
      @if (!exercise.isResetInProgress) {
        <span
          class="status-circle"
          [ngClass]="{
            running: exercise.status === 'RUNNING',
            stopped: exercise.status === 'STOPPED',
            'not-started': exercise.status === 'NOT_STARTED',
            unpublished: exercise.status === 'UNPUBLISHED',
            scheduled: exercise.status === 'SCHEDULED'
          }"
        ></span>
      } @else {
        <cybexer-progress-spinner></cybexer-progress-spinner>
      }
      @if (!exercise.isResetInProgress) {
        <span>
          @switch (exercise.status) {
            @case ('RUNNING') {
              <span>Running</span>
            }
            @case ('STOPPED') {
              <span>Stopped</span>
            }
            @case ('NOT_STARTED') {
              <span>Not Started</span>
            }
            @case ('UNPUBLISHED') {
              <span>Unpublished</span>
            }
            @case ('SCHEDULED') {
              <span>Scheduled</span>
            }
          }
        </span>
      } @else {
        <span>
          Reset in progress
          @if (!showResetSteps) {
            <i
              class="fas fa-info-circle info-icon"
              matTooltip="Connection to count reset steps is closed. Please wait until exercise has resetted."
            ></i>
          } @else {
            <span>
              | step {{ exerciseResetSteps.get(exercise.id)?.stepsCompleted || 0 }} out of
              {{ exerciseResetSteps.get(exercise.id)?.totalSteps || MAX_STEPS }}
            </span>
          }
        </span>
      }
    </div>
    <div class="disable-uppercase description">
      {{ exercise.description }}
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button (click)="selectExercise(exercise.id)" mat-button>
      <i class="fas fa-rocket" aria-hidden="true"></i>Select
    </button>
    @if (exercise.isTeamSettingsEnabled && isBlueTeam(exercise.id)) {
      <button (click)="openTeamSettings(exercise)" mat-button>
        @if (!exercise.isIndividualAssessment) {
          <i class="fas fa-users" aria-hidden="true"></i>Team settings
        } @else {
          <i class="fas fa-user" aria-hidden="true"></i>User settings
        }
      </button>
    }
    @if (exercise.type !== ExerciseType.UNPUBLISHED) {
      <button
        *isaRequirePermission="getSystemEventsPermissions(exercise.id); withTarget: true"
        class="start-btn"
        (click)="openExerciseConfirmDialog(exercise, 'STARTEX')"
        mat-button
        [disabled]="exercise.status === 'RUNNING' || exercise.isResetInProgress"
      >
        <i class="fas fa-play" aria-hidden="true"></i> Start
      </button>
      <button
        *isaRequirePermission="getSystemEventsPermissions(exercise.id); withTarget: true"
        class="stop-btn"
        (click)="openExerciseConfirmDialog(exercise, 'ENDEX')"
        mat-button
        [disabled]="exercise.status !== 'RUNNING'"
      >
        <i class="fas fa-stop" aria-hidden="true"></i> Stop
      </button>
    }
    <button class="more-info-btn" (click)="openExerciseDialog(exercise)" mat-button>
      <i class="fas fa-info-circle" aria-hidden="true"></i>More info
    </button>
    <ng-container *isaRequirePermission="getSystemEventsPermissions(exercise.id); withTarget: true">
      <button mat-icon-button class="more-actions-button" [matMenuTriggerFor]="moreActionsMenu">
        <i
          [ngClass]="isProcessing ? 'fas fa-spinner fa-spin' : 'fas fa-ellipsis-h'"
          aria-hidden="true"
        ></i>
      </button>
      <mat-menu
        xPosition="before"
        #moreActionsMenu="matMenu"
        [overlapTrigger]="false"
        class="more-actions-menu"
      >
        <button
          mat-menu-item
          data-testid="edit-exercise-btn"
          *isaRequirePermission="EXERCISE_PERMISSIONS.ADMIN"
          [routerLink]="['/app/gamenet/exercise-definition', exercise.id]"
        >
          <i class="fas fa-edit" aria-hidden="true"></i> Edit
        </button>
        <mat-divider></mat-divider>
        <button
          mat-menu-item
          *isaRequirePermission="[EXERCISE_PERMISSIONS.ADMIN, EXERCISE_PERMISSIONS.WHITE_TEAM]"
          [routerLink]="['/app/gamenet/team-assignment', exercise.id]"
          data-testid="pre-assign-teams-btn"
        >
          <i class="fas fa-users" aria-hidden="true"></i> Pre-assign teams
        </button>
        @if (exercise.type === ExerciseType.CTF) {
          <button
            mat-menu-item
            *isaRequirePermission="[EXERCISE_PERMISSIONS.ADMIN, EXERCISE_PERMISSIONS.WHITE_TEAM]"
            [routerLink]="['/app/gamenet/waiting-room/statistics', exercise.id]"
            data-testid="queue-stats-btn"
          >
            <i class="fas fa-chart-line" aria-hidden="true"></i> Monitoring
          </button>
        }
        <button
          mat-menu-item
          *isaRequirePermission="EXERCISE_PERMISSIONS.ADMIN"
          (click)="openExerciseIntegrationsDialog(exercise)"
        >
          <i class="fas fa-plug" aria-hidden="true"></i> Integrations
        </button>
        <button
          mat-menu-item
          *isaRequirePermission="EXERCISE_PERMISSIONS.ADMIN"
          (click)="openExerciseImageDialog(exercise)"
          data-testid="edit-exercise-image-btn"
        >
          <i class="fas fa-image" aria-hidden="true"></i>
          {{ exercise.imageId ? 'Edit' : 'Add' }} image
        </button>
        @if (exercise.type === ExerciseType.CAMPAIGN) {
          <button
            mat-menu-item
            (click)="reloadScripts(exercise.id)"
            [disabled]="isProcessing || exercise.status === 'UNPUBLISHED'"
            data-testid="reload-scripts-btn"
          >
            <i class="fas fa-sync" aria-hidden="true"></i> Reload scripts
          </button>
        }
        <mat-divider></mat-divider>
        <button
          mat-menu-item
          *isaRequirePermission="EXERCISE_PERMISSIONS.ADMIN"
          (click)="openExerciseResetDialog(exercise)"
          [disabled]="exercise.status !== 'STOPPED' || exercise.isResetInProgress"
          data-testid="reset-exercise-btn"
        >
          <i class="fas fa-undo-alt" aria-hidden="true"></i> Reset
        </button>
        <div
          matTooltip="Reset before deleting"
          matTooltipPosition="left"
          [matTooltipDisabled]="exercise.status === ExerciseStatus.NOT_STARTED"
        >
          <button
            mat-menu-item
            *isaRequirePermission="EXERCISE_PERMISSIONS.ADMIN"
            (click)="openExerciseDeleteDialog(exercise)"
            [disabled]="exercise.status !== ExerciseStatus.NOT_STARTED"
            data-testid="delete-exercise-btn"
          >
            <i class="fas fa-trash-alt" aria-hidden="true"></i>
            Delete
          </button>
        </div>
      </mat-menu>
    </ng-container>
  </mat-card-actions>
</cybexer-card>
