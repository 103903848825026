import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationsService } from '@cybexer/ngx-commons';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UserDetails } from '../../../models';
import { UsersService } from '../../../services';
import {
  PasswordChangeDialogComponent,
  PasswordChangeDialogInput,
} from '../../shared/password-change-dialog/password-change-dialog.component';
import { RoleAssignmentResult } from '../role-assignment-table/role-assignment-dialog/role-assignment-dialog.component';

@UntilDestroy()
@Component({
  selector: 'isa-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent {
  userChanged$ = new BehaviorSubject<boolean>(true);
  user$ = this.observeUser(this.route.params, this.userChanged$);
  passwordChangeDialogRef: MatDialogRef<PasswordChangeDialogComponent>;

  constructor(
    private notificationsService: NotificationsService,
    private usersService: UsersService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  observeUser(
    routeParams: Observable<Params>,
    userChanged$: Observable<boolean>
  ): Observable<UserDetails> {
    return combineLatest([routeParams, userChanged$]).pipe(
      map(([params]) => {
        // Destructure the result of the first observable (route params)
        return params['username'];
      }),
      switchMap((username) => this.usersService.getUser(username)),
      untilDestroyed(this)
    );
  }

  onRoleAssign(assignmentResult: RoleAssignmentResult, username: string) {
    const action$ = assignmentResult.isEnabled
      ? this.usersService.saveRole(username, assignmentResult)
      : this.usersService.removeRole(username, assignmentResult.code);
    action$.subscribe({
      next: () => {
        this.notificationsService.success('Role saved');
        this.userChanged$.next(true);
      },
      error: () => {
        this.notificationsService.error('Failed to save role');
      },
    });
  }

  displayRoles(user: UserDetails): string {
    return user.roles.map((it) => it.code).join(', ');
  }

  openPasswordChangeDialog(username: string) {
    this.passwordChangeDialogRef = this.dialog.open(PasswordChangeDialogComponent, {
      disableClose: false,
      data: {
        onPasswordChange: (password: string) =>
          this.usersService.changePassword(username, password),
      } as PasswordChangeDialogInput,
    });

    this.passwordChangeDialogRef.afterClosed().subscribe(() => {
      this.passwordChangeDialogRef = null;
    });
  }
}
