import {
  AfterViewInit,
  Component,
  effect,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  CTFTaskStatus,
  Exercise,
  SmartCityBuilding,
  SmartCityBuildingDetails,
  SmartCityMapConfiguration,
  TargetStatus,
} from '../../../../models';
import {
  AbstractMesh,
  Animation,
  AnimationGroup,
  ArcRotateCamera,
  CascadedShadowGenerator,
  Color3,
  Color4,
  DirectionalLight,
  Engine,
  HemisphericLight,
  Mesh,
  PBRMaterial,
  ReflectionProbe,
  Scene,
  SceneLoader,
  ShadowGenerator,
  Space,
  StandardMaterial,
  TransformNode,
  Vector3,
  Viewport,
} from '@babylonjs/core';
import '@babylonjs/loaders/glTF';
import { GlowLayer } from '@babylonjs/core/Layers/glowLayer';
import { AdvancedDynamicTexture, Control } from '@babylonjs/gui';
import { SSAO2RenderingPipeline } from '@babylonjs/core/PostProcesses/RenderPipeline/Pipelines/ssao2RenderingPipeline';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import { WindowRefService } from '../../../../services/shared/window-ref.service';
import { SidebarService } from '../../../../services/shared/sidebar.service';
import { SmartCityService } from '../../../../services/gamenet/smart-city.service';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'isa-smart-city-visualization',
  templateUrl: './smart-city-visualization.component.html',
  styleUrl: './smart-city-visualization.component.css',
})
export class SmartCityVisualizationComponent implements OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;
  @Input() cityState: SmartCityBuilding[];
  @Input() selectedCity: SmartCityMapConfiguration;
  @Input() selectedBuildingDetails: SmartCityBuildingDetails;
  @Input() teamId: string;
  @Output() showPanel = new EventEmitter<string>();
  isMapOpen = true;
  isVehicleCameraActive = false;
  exercise: Exercise;
  loading = true;
  selectedMesh: AbstractMesh | null = null;
  isInitialized = false;
  isInfoPanelOpen = this.smartCityService.isInfoPanelOpen;
  drone: AbstractMesh | null = null;
  droneFlyAway = new BehaviorSubject(false);
  scene: Scene;
  engine: Engine;
  private readonly headerHeight = 64;
  private readonly sideMenuWidth = 320;
  private camera: ArcRotateCamera;
  private guiCamera: ArcRotateCamera;
  private mapCamera: ArcRotateCamera;
  private gl: GlowLayer;
  private selectMaterial: PBRMaterial;
  private meshOriginalMaterials: Map<AbstractMesh, any> = new Map();
  private materialDefault: PBRMaterial | null = null;
  private materialUnderAttack: PBRMaterial | null = null;
  private materialInactive: PBRMaterial | null = null;
  private materialCompromised: PBRMaterial | null = null;
  private materialSolved: PBRMaterial | null = null;
  private materialNotAvailable: PBRMaterial | null = null;
  private autoRotationInterval: NodeJS.Timeout | undefined;
  private userActiveTimer: NodeJS.Timeout | undefined;
  private height: number = 40;
  private depth: number = 40;
  private mapBackgroundBox: Control;
  private light: DirectionalLight;
  private shadowGenerator: CascadedShadowGenerator;
  private initialCameraState: { alpha: number; beta: number; radius: number; target: Vector3 };
  private droneFlyAwaySubscription: Subscription;

  constructor(
    private ngZone: NgZone,
    private windowRef: WindowRefService,
    private sideBarService: SidebarService,
    private smartCityService: SmartCityService
  ) {
    effect(() => {
      if (!this.isInfoPanelOpen() && this.selectedBuildingDetails) {
        this.resetSelectedBulding();
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.init());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isInitialized) {
      if (changes.cityState) {
        this.processMeshes();
      }

      if (changes.selectedCity) {
        this.mapChange();
      }

      if (changes.teamId) {
        this.teamChange();
      }
    }
  }

  init() {
    this.createScene();
    this.createGrid(34, 24, 1, 0.05);
    this.animate();
    this.initSideNavListener();
    this.isInitialized = true;
  }
  createScene(): void {
    SceneLoader.ShowLoadingScreen = false;
    this.engine = new Engine(this.canvas.nativeElement, true, null, false);

    // create a basic BJS Scene object
    this.scene = new Scene(this.engine);
    this.scene.clearColor = new Color4(0.33, 0.38, 0.43, 1);
    this.scene.ambientColor = new Color3(1, 1, 1);

    this.gl = new GlowLayer('glow', this.scene, {
      blurKernelSize: 32,
    });
    this.gl.intensity = 0.75;

    // Create SSAO2 rendering pipeline and configure parameters
    const ssaoRatio = {
      ssaoRatio: 0.5, // Ratio of the SSAO post-process, in a lower resolution
      combineRatio: 1.0, // Ratio of the combine post-process (combines the SSAO and the scene)
    };
    const ssao2 = new SSAO2RenderingPipeline('ssao2', this.scene, ssaoRatio);
    ssao2.radius = 0.1; // Adjust the radius
    ssao2.totalStrength = 1.0; // Adjust the total strength
    ssao2.base = 0.5; // Adjust the base

    this.initMaterials();

    // Set up fog in the scene
    this.scene.fogMode = Scene.FOGMODE_LINEAR;
    this.scene.fogColor = new Color3(0.33, 0.38, 0.43);
    this.scene.fogDensity = 0.1;

    this.initLightAndShadow();
    this.initCameras();

    AdvancedDynamicTexture.ParseFromFileAsync('assets/3D_geo/UI/guiTexture.json')
      .then((gui: AdvancedDynamicTexture) => {
        this.mapBackgroundBox = gui.getControlByName('mapBackgroundBox');
        this.mapBackgroundBox.isVisible = true;
        gui.layer.layerMask = this.guiCamera.layerMask;
      })
      .catch((error: any) => {
        console.error('Error loading GUI:', error);
      });

    this.mapChange();

    this.scene.executeWhenReady(() => {
      this.processMeshes();
    });
  }

  private initLightAndShadow() {
    // create a basic light, aiming 0,1,0 - meaning, to the sky
    this.light = new DirectionalLight('light2', new Vector3(1, -2, -1), this.scene);
    this.light.intensity = 1;
    this.light.position = new Vector3(0, 150, -30);

    const hemisphericLight = new HemisphericLight('lighthemy', new Vector3(0.5, 1, 0), this.scene);
    hemisphericLight.intensity = 0.5;
    hemisphericLight.diffuse = new Color3(0.4, 0.4, 0.4); // Adjust diffuse color

    this.shadowGenerator = new CascadedShadowGenerator(2048, this.light);
    this.shadowGenerator.bias = 0.0005;
    this.shadowGenerator.usePercentageCloserFiltering = true;
    this.shadowGenerator.filteringQuality = ShadowGenerator.QUALITY_HIGH;
    this.shadowGenerator.stabilizeCascades = true;
  }

  private initCameras() {
    this.camera = new ArcRotateCamera('camera1', 1.5, 0.8, 10, new Vector3(-10, -5, 4), this.scene);
    this.initialCameraState = {
      alpha: this.camera.alpha,
      beta: this.camera.beta,
      radius: this.camera.radius,
      target: this.camera.target.clone(),
    };

    this.camera.lowerBetaLimit = 0.5; // Minimum vertical angle in radians (adjust as needed)
    this.camera.upperBetaLimit = Math.PI / 2 - 0.5; // Maximum vertical angle in radians (adjust as needed)
    this.camera.attachControl(this.canvas, true);
    this.camera.wheelPrecision = 5; // Adjust as needed
    this.camera.minZ = 0.1; // Adjust as needed
    this.camera.maxZ = 200; // Adjust as needed
    this.camera.lowerRadiusLimit = 5; // Adjust as needed
    this.camera.upperRadiusLimit = 30; // Adjust as needed
    this.camera.panningSensibility = 50; // Optional: Disable panning
    this.camera.angularSensibilityX = 250;
    this.camera.inertia = 0;
    this.camera.panningInertia = 0;
    this.camera.wheelDeltaPercentage = 0.05;

    this.startAutoRotation();
    this.setupUserInteractionListeners();
    this.startUserActivityTimer();

    this.guiCamera = new ArcRotateCamera('guicamera', 0, 0, 30, new Vector3(0, 0, 0), this.scene);
    this.guiCamera.attachControl(this.canvas, true);
    this.guiCamera.layerMask = 0x20000000;

    this.mapCamera = new ArcRotateCamera(
      'camera1',
      Math.PI / 1.95,
      0,
      0,
      new Vector3(-6, 40, -2),
      this.scene
    );

    let sizeW = 280;
    let sizeH = 280;
    const onResize = () => {
      let percW = sizeW / this.canvas.nativeElement.width;
      let percH = sizeH / this.canvas.nativeElement.height;
      this.mapCamera.viewport = new Viewport(1 - percW * 1.055, 1 - percH * 1.06, percW, percH);
    };
    this.scene.cameraToUseForPointers = this.guiCamera;
    this.engine.onResizeObservable.add(() => onResize());

    onResize();

    this.camera.viewport = new Viewport(0, 0, 1, 1);

    // Add onBeforeRenderObservable to enforce constraints on camera target
    this.scene.onBeforeRenderObservable.add(() => {
      const xLimitplus = 0;
      const xLimit = -10;
      const yLimit = this.height / 2;
      const zLimit = this.depth / 2;

      // Apply constraints to camera target's position
      if (this.camera.target.x < xLimit) {
        this.camera.target.x = xLimit;
      } else if (this.camera.target.x > xLimitplus) {
        this.camera.target.x = xLimitplus;
      }

      if (this.camera.target.z < -yLimit) {
        this.camera.target.z = -yLimit;
      } else if (this.camera.target.z > yLimit) {
        this.camera.target.z = yLimit;
      }
      if (this.camera.target.y < 2) {
        this.camera.target.y = 2;
      } else if (this.camera.target.y > zLimit) {
        this.camera.target.y = zLimit;
      }
    });
  }

  private initMaterials() {
    this.materialDefault = new PBRMaterial('material', this.scene);
    //this.materialA.ambientColor = new Color3(.1, .1, .1);
    this.materialDefault.albedoColor = new Color3(1, 1, 1);

    this.materialDefault.roughness = 1.0;
    this.materialDefault.metallic = 0.0;

    this.materialInactive = new PBRMaterial('material', this.scene);
    this.materialInactive.albedoColor = new Color3(0.3, 0.3, 0.3);
    this.materialInactive.roughness = 1.0;
    this.materialInactive.metallic = 0.0;

    this.materialCompromised = new PBRMaterial('material', this.scene);
    this.materialCompromised.albedoColor = new Color3(1.2, 0.53, 0.18);
    this.materialCompromised.roughness = 1.0;
    this.materialCompromised.metallic = 0.0;

    this.materialSolved = new PBRMaterial('material', this.scene);
    this.materialSolved.albedoColor = new Color3(0.2, 1.23, 0.18);
    this.materialSolved.roughness = 1.0;
    this.materialSolved.metallic = 0.0;

    this.materialNotAvailable = new PBRMaterial('material', this.scene);
    this.materialNotAvailable.albedoColor = new Color3(1.6, 0.2, 0.2);
    this.materialNotAvailable.roughness = 1.0;
    this.materialNotAvailable.metallic = 0.0;

    const materialM = new PBRMaterial('material', this.scene);
    materialM.ambientColor = new Color3(0.1, 0.1, 0.1);
    materialM.albedoColor = new Color3(1, 1, 1);
    materialM.roughness = 1.0;
    materialM.metallic = 0.0;

    this.materialUnderAttack = new PBRMaterial('material', this.scene);
    this.materialUnderAttack.albedoColor = new Color3(1.6, 0.2, 0.2);
    this.materialUnderAttack.roughness = 1.0;
    this.materialUnderAttack.metallic = 0.0;

    // Create a new material for selected meshes
    const probe = new ReflectionProbe('main', 512, this.scene);
    this.selectMaterial = new PBRMaterial('newMaterial', this.scene);
    this.selectMaterial.reflectionTexture = probe.cubeTexture;
    this.selectMaterial.roughness = 0.0;
    this.selectMaterial.metallic = 1.0;
    this.selectMaterial.emissiveIntensity = 1;
    this.selectMaterial.emissiveColor = new Color3(0.4, 0.2, 0.4);
    this.selectMaterial.albedoColor = new Color3(0.8, 0.0, 0.3);

    // Define the animation keys for color change
    const keys = [];
    keys.push({
      frame: 0,
      value: Color3.White(),
    });
    keys.push({
      frame: 30,
      value: new Color3(1.6, 0.2, 0.2),
    });
    keys.push({
      frame: 80,
      value: new Color3(1.6, 0.2, 0.2),
    });

    // Create an animation
    const colorAnimation = new Animation(
      'colorAnimation', // Animation name
      'albedoColor', // Property to animate
      25, // Frame rate
      Animation.ANIMATIONTYPE_COLOR3, // Animation type
      Animation.ANIMATIONLOOPMODE_YOYO // Loop mode
    );

    // Set animation keys
    colorAnimation.setKeys(keys);

    // Attach the animation to the material
    this.materialUnderAttack.animations = [];
    this.materialUnderAttack.animations.push(colorAnimation);

    // Start the animation
    this.scene.beginAnimation(this.materialUnderAttack, 0, 30, true); // 0 to 30 frames
  }

  private startAutoRotation() {
    if (!this.autoRotationInterval) {
      this.autoRotationInterval = setInterval(() => {
        // Update the camera alpha and beta angles
        this.camera.alpha += 0.0005; // Adjust rotation speed as needed
      }, 32); // Adjust the interval as needed for smoother or slower rotation
    }
  }

  private stopAutoRotation() {
    if (this.autoRotationInterval) {
      clearInterval(this.autoRotationInterval);
      this.autoRotationInterval = undefined;
    }
  }

  private setupUserInteractionListeners() {
    document.addEventListener('pointerdown', () => {
      this.stopAutoRotation();
      this.resetUserActivityTimer();
    });

    document.addEventListener('pointermove', () => {
      this.stopAutoRotation();
      this.resetUserActivityTimer();
    });

    document.addEventListener('pointerup', () => {
      this.stopAutoRotation();
      this.resetUserActivityTimer();
    });
  }

  private startUserActivityTimer() {
    this.userActiveTimer = setTimeout(() => {
      // User has been inactive, start auto rotation
      this.startAutoRotation();
    }, 5000); // Adjust the delay (in milliseconds) as needed
  }

  private resetUserActivityTimer() {
    if (this.userActiveTimer) {
      clearTimeout(this.userActiveTimer);
    }
    this.startUserActivityTimer();
  }

  processMeshes() {
    if (this.scene) {
      const activeMeshes = this.scene.meshes.filter((mesh) => mesh.name.startsWith('ID_'));

      activeMeshes.forEach((mesh) => {
        // do not apply new material if mesh is selected
        const building = this.getDataByID(mesh.name);
        if (!(this.selectedBuildingDetails && this.selectedBuildingDetails.id === mesh.name)) {
          if (
            building?.target?.status === TargetStatus.NOT_AVAILABLE ||
            building?.task?.status === CTFTaskStatus.NOT_STARTED ||
            building?.task?.status === CTFTaskStatus.DEPENDENCIES_UNSOLVED ||
            building?.task?.status === CTFTaskStatus.ABANDONED
          ) {
            mesh.material = this.materialNotAvailable;
            mesh.material.fogEnabled = false;
          } else if (
            building?.target?.status === TargetStatus.COMPROMISED ||
            building?.task?.status === CTFTaskStatus.PARTLY_SOLVED ||
            building?.task?.status === CTFTaskStatus.VALIDATING
          ) {
            mesh.material = this.materialCompromised;
            mesh.material.fogEnabled = false;
          } else if (building?.task?.status === CTFTaskStatus.SOLVED) {
            mesh.material = this.materialSolved;
            mesh.material.fogEnabled = false;
          } else if (building?.target?.isUnderAttack) {
            mesh.material = this.materialUnderAttack;
            mesh.material.fogEnabled = false;
          } else {
            mesh.material = this.materialDefault;
            mesh.material.fogEnabled = false;
          }
        }
      });

      this.checkDroneStatus();
    }
  }

  checkDroneStatus() {
    if (!this.selectedCity.drone) return;

    const building = this.getDataByID(this.selectedCity.drone.buildingId);
    const affectedAnimation = this.scene.getAnimationGroupByName(
      this.selectedCity.drone.affectedAnimationName
    );

    if (this.isTaskSolved(building)) {
      this.handleDroneTaskSolved(affectedAnimation);
    } else {
      this.handleDroneTaskNotSolved(affectedAnimation);
    }
  }

  isTaskSolved(building: SmartCityBuilding): boolean {
    return building?.task?.status === CTFTaskStatus.SOLVED;
  }

  handleDroneTaskSolved(affectedAnimation: AnimationGroup) {
    this.droneFlyAway.next(true);
    if (affectedAnimation) {
      affectedAnimation.start();
    }
  }

  handleDroneTaskNotSolved(affectedAnimation: AnimationGroup | undefined) {
    if (!this.drone) {
      this.addDrone(this.selectedCity.drone.coordinates);
    }
    if (this.selectedCity.name === 'Tartu' && affectedAnimation) {
      affectedAnimation.goToFrame(3000);
    }
    affectedAnimation?.stop();
  }

  getDataByID(id: string): SmartCityBuilding {
    if (!this.cityState) return;
    return this.cityState.find((item: any) => item.id === id);
  }

  createGrid(xLines: number, zLines: number, spacing: number, transparency: number): void {
    const halfX = ((xLines - 1) * spacing) / 2;
    const halfZ = ((zLines - 1) * spacing) / 2;

    // Create lines along the X axis
    for (let i = 0; i < xLines; i++) {
      const xPos = i * spacing - halfX;
      const pointsX = [new Vector3(xPos, 0, -halfZ), new Vector3(xPos, 0, halfZ)];

      const linesX = MeshBuilder.CreateLines(`gridX-${i}`, { points: pointsX }, this.scene);
      linesX.color = new Color3(1, 0.8, 0.1); // Set RGB values
      linesX.alpha = transparency; // Set alpha (transparency) value
      linesX.isPickable = false;
    }

    // Create lines along the Z axis
    for (let i = 0; i < zLines; i++) {
      const zPos = i * spacing - halfZ;
      const pointsZ = [new Vector3(-halfX, 0, zPos), new Vector3(halfX, 0, zPos)];

      const linesZ = MeshBuilder.CreateLines(`gridZ-${i}`, { points: pointsZ }, this.scene);
      linesZ.color = new Color3(1, 0.8, 0.1); // Set RGB values
      linesZ.alpha = transparency; // Set alpha (transparency) value
      linesZ.isPickable = false;
    }
  }

  private addElecticityGrid() {
    SceneLoader.ImportMesh(
      '',
      'assets/3D_geo/Tartu/',
      'grid_test.gltf',
      this.scene,
      (newMeshes) => {
        if (newMeshes.length > 0) {
          newMeshes.forEach((mesh) => {
            if (mesh.material) {
              const material = mesh.material;

              if (material instanceof PBRMaterial) {
                const texture = material.albedoTexture; // Base Color texture

                if (texture) {
                  // Create an animation for the vOffset property
                  const vOffsetAnimation = new Animation(
                    'vOffsetAnimation',
                    'vOffset',
                    30, // Frames per second
                    Animation.ANIMATIONTYPE_FLOAT,
                    Animation.ANIMATIONLOOPMODE_CYCLE
                  );

                  // Animation keys
                  const keys = [
                    { frame: 0, value: 0 },
                    { frame: 20, value: 1 }, // 20 frames long animation
                  ];

                  vOffsetAnimation.setKeys(keys);

                  // Add the animation to the texture
                  texture.animations = [];
                  texture.animations.push(vOffsetAnimation);

                  // Start the animation
                  this.scene.beginAnimation(texture, 0, 20, true);
                }
              }
            }
          });
        }
      }
    );
  }

  private addCybexerLogo() {
    SceneLoader.ImportMesh(
      '',
      'assets/3D_geo/',
      'CYBEXER_logo.gltf',
      this.scene,
      function (newMeshes) {
        for (const mesh of newMeshes) {
          if (mesh.name.startsWith('CybexerLogo')) mesh.billboardMode = Mesh.BILLBOARDMODE_Y;
          mesh.translate(new Vector3(0.45, -0.09, -1.49), Space.WORLD);
        }
      }
    );
  }

  switchToTruckCamera() {
    if (this.scene.getCameraByName('followCamera')) {
      this.scene.activeCameras = [
        this.scene.getCameraByName('followCamera'),
        this.guiCamera,
        this.mapCamera,
      ];
      this.isVehicleCameraActive = true;
    }
  }

  addDrone(droneCoordinates) {
    SceneLoader.ImportMesh('', 'assets/3D_geo/', 'drone.glb', this.scene, (meshes) => {
      this.drone = meshes[0];
      // Define the animation
      const droneAnimation = new Animation(
        'droneAnimation',
        'position',
        30,
        Animation.ANIMATIONTYPE_VECTOR3,
        Animation.ANIMATIONLOOPMODE_CYCLE
      );

      const keys = [];
      const numFrames = 120; // Number of frames for one loop
      for (let i = 0; i <= numFrames; i++) {
        const t = (i / numFrames) * 2 * Math.PI;
        const x = Math.sin(t);
        const y = Math.sin(t) * Math.cos(t);
        keys.push({
          frame: i,
          value: new Vector3(y + droneCoordinates.x, droneCoordinates.y, x + droneCoordinates.z),
        });
      }

      droneAnimation.setKeys(keys);

      this.drone.animations = [];
      this.drone.animations.push(droneAnimation);

      // Start the animation
      this.scene.beginAnimation(this.drone, 0, numFrames, true);

      this.droneFlyAwaySubscription = this.droneFlyAway.subscribe((value) => {
        if (value) {
          this.removeDrone();
          this.droneFlyAwaySubscription.unsubscribe();
        }
      });
    });
  }

  private removeDrone() {
    if (this.drone) {
      this.scene.stopAnimation(this.drone);
      const flyAwayAnimation = new Animation(
        'flyAwayAnimation',
        'position',
        30,
        Animation.ANIMATIONTYPE_VECTOR3,
        Animation.ANIMATIONLOOPMODE_CONSTANT
      );

      const flyAwayKeys = [
        { frame: 0, value: this.drone.position.clone() },
        {
          frame: 60,
          value: new Vector3(
            this.drone.position.x,
            this.drone.position.y + 10,
            this.drone.position.z + 20
          ),
        },
      ];

      flyAwayAnimation.setKeys(flyAwayKeys);
      this.drone.animations = [];
      this.drone.animations.push(flyAwayAnimation);

      // Start the fly-away animation
      this.scene.beginAnimation(this.drone, 0, 60, false, 1, () => {
        this.drone.dispose();
        this.drone = null;
        this.droneFlyAway.next(false);
      });

      if (this.selectedCity.drone.affectedAnimationName) {
        this.scene.getAnimationGroupByName(this.selectedCity.drone.affectedAnimationName).start();
      }
    }
  }

  addRadioTower({ x, y, z }) {
    const tower = SceneLoader.ImportMesh(
      '',
      'assets/3D_geo/radioSignal/',
      'tower.gltf',
      this.scene,
      (meshes) => {
        meshes[0].position = new Vector3(x, y, z);
      }
    );

    const radioTowerHeight = 1.2;
    const torus1 = this.createRadioSignal('torus1', 0.5, new Vector3(x, radioTowerHeight, z));
    const torus2 = this.createRadioSignal('torus2', 0.5, new Vector3(x, radioTowerHeight, z));
    const torus3 = this.createRadioSignal('torus3', 0.5, new Vector3(x, radioTowerHeight, z));
    const torus4 = this.createRadioSignal('torus4', 0.5, new Vector3(x, radioTowerHeight, z));

    // Create a master animation to control the start times of the torus animations
    const masterAnimation = new Animation(
      'masterAnimation',
      'position.x',
      30,
      Animation.ANIMATIONTYPE_FLOAT,
      Animation.ANIMATIONLOOPMODE_CYCLE
    );

    const masterKeys = [];
    masterKeys.push({ frame: 0, value: 1 });
    masterKeys.push({ frame: 30, value: 2 });
    masterKeys.push({ frame: 60, value: 3 });
    masterKeys.push({ frame: 90, value: 4 });
    masterKeys.push({ frame: 120, value: 5 });
    masterAnimation.setKeys(masterKeys);

    // Create a animation timer object to attach the master animation
    const animationTimer = new TransformNode('animationTimer', this.scene);
    animationTimer.animations = [masterAnimation];

    this.scene.beginAnimation(animationTimer, 0, 120, true);

    this.scene.onBeforeRenderObservable.add(() => {
      const frame = Math.floor(animationTimer.position.x);

      if (frame === 1) {
        this.scene.beginAnimation(torus1, 0, 120, false);
      } else if (frame === 2) {
        this.scene.beginAnimation(torus2, 0, 120, false);
      } else if (frame === 3) {
        this.scene.beginAnimation(torus3, 0, 120, false);
      } else if (frame === 4) {
        this.scene.beginAnimation(torus4, 0, 120, false);
      }
    });
  }

  createRadioSignal(name: string, diameter: number, position: Vector3) {
    const torus = MeshBuilder.CreateTorus(
      name,
      {
        thickness: 0.005,
        diameter: diameter,
        tessellation: 64,
      },
      this.scene
    );

    torus.position = position;

    const material = new StandardMaterial(name + 'Material', this.scene);
    material.emissiveColor = new Color3(1, 0, 0); // Red glow
    torus.material = material;

    const scaleXAnimation = new Animation(
      name + 'ScaleXAnimation',
      'scaling.x',
      30,
      Animation.ANIMATIONTYPE_FLOAT,
      Animation.ANIMATIONLOOPMODE_CONSTANT
    );

    const scaleZAnimation = new Animation(
      name + 'ScaleZAnimation',
      'scaling.z',
      30,
      Animation.ANIMATIONTYPE_FLOAT,
      Animation.ANIMATIONLOOPMODE_CONSTANT
    );

    const scaleKeys = [];
    scaleKeys.push({ frame: 0, value: 1 });
    scaleKeys.push({ frame: 120, value: 16 });

    scaleXAnimation.setKeys(scaleKeys);
    scaleZAnimation.setKeys(scaleKeys);

    torus.animations.push(scaleXAnimation);
    torus.animations.push(scaleZAnimation);

    const visibilityAnimation = new Animation(
      name + 'VisibilityAnimation',
      'visibility',
      30,
      Animation.ANIMATIONTYPE_FLOAT,
      Animation.ANIMATIONLOOPMODE_CONSTANT
    );

    const visibilityKeys = [];
    visibilityKeys.push({ frame: 0, value: 0 });
    visibilityKeys.push({ frame: 1, value: 1 });
    visibilityKeys.push({ frame: 120, value: 0 });

    visibilityAnimation.setKeys(visibilityKeys);
    torus.animations.push(visibilityAnimation);

    const emissiveColorAnimation = new Animation(
      name + 'EmissiveColorAnimation',
      'material.emissiveColor',
      30,
      Animation.ANIMATIONTYPE_COLOR3,
      Animation.ANIMATIONLOOPMODE_CONSTANT
    );

    const emissiveColorKeys = [];
    emissiveColorKeys.push({ frame: 0, value: new Color3(1, 0, 0) });
    emissiveColorKeys.push({ frame: 120, value: new Color3(0, 0, 0) });

    emissiveColorAnimation.setKeys(emissiveColorKeys);
    torus.animations.push(emissiveColorAnimation);

    return torus;
  }

  animate(): void {
    // We have to run this outside angular zones,
    // because it could trigger heavy changeDetection cycles.
    this.ngZone.runOutsideAngular(() => {
      const rendererLoopCallback = () => {
        this.scene.render();
      };

      // Register pointer event inside animate
      this.scene.onPointerDown = (event, pickInfo) => {
        this.closeInfoPanel();
        if (event.button === 0) {
          const pickInfo = this.scene.pick(
            this.scene.pointerX,
            this.scene.pointerY,
            undefined,
            false,
            this.camera
          );

          if (pickInfo.hit && pickInfo.pickedMesh) {
            const transformNodeName = pickInfo.pickedMesh.parent
              ? pickInfo.pickedMesh.parent.name
              : 'No parent transform node';
            if (pickInfo.pickedMesh.name && pickInfo.pickedMesh.name.startsWith('ID')) {
              // Update selected mesh and set its outline color
              this.selectedMesh = pickInfo.pickedMesh;
              this.selectedMesh.edgesColor = new Color4(1, 0.2, 0, 1);

              // Save the original material of the selected mesh
              this.meshOriginalMaterials.set(this.selectedMesh, this.selectedMesh.material);

              // Assign the predefined new material to the selected mesh
              this.selectedMesh.material = this.selectMaterial;
              const pickedMesh = pickInfo.pickedMesh;
              this.showPanel.emit(pickedMesh.name);
            }
          }
        }
      };

      // Add onViewMatrixChangedObservable to hide StackPanel when the camera view matrix changes
      this.camera.onViewMatrixChangedObservable.add(() => {
        // TODO hide info panel when camera starts rotating?
      });

      if (this.windowRef.document.readyState !== 'loading') {
        this.engine.runRenderLoop(rendererLoopCallback);
      } else {
        this.windowRef.window.addEventListener('DOMContentLoaded', () => {
          this.engine.runRenderLoop(rendererLoopCallback);
        });
      }

      this.windowRef.window.addEventListener('resize', () => {
        this.engine.resize();
      });
    });
  }

  initSideNavListener() {
    this.sideBarService.sidenavState$.subscribe((state) => {
      setTimeout(() => this.engine.resize(), 300);
    });
  }

  resetCamera(): void {
    this.camera.setPosition(
      new Vector3(
        this.initialCameraState.target.x,
        this.initialCameraState.target.y,
        this.initialCameraState.target.z
      )
    );
    this.camera.alpha = this.initialCameraState.alpha;
    this.camera.beta = this.initialCameraState.beta;
    this.camera.radius = this.initialCameraState.radius;
    this.camera.setTarget(this.initialCameraState.target);
  }

  mapChange() {
    this.closeInfoPanel();
    this.isVehicleCameraActive = false;
    this.resetCamera();
    this.height = this.selectedCity.height;
    this.depth = this.selectedCity.depth;
    this.scene.fogStart = this.selectedCity.fogStart;
    this.scene.fogEnd = this.selectedCity.fogEnd;
    this.scene.meshes.forEach((mesh) => {
      mesh.dispose();
    });
    this.createGrid(60, 40, 1, 0.05);
    this.loadCityScene(
      this.shadowGenerator,
      this.selectedCity.scenePath,
      this.selectedCity.sceneFile,
      () => {
        this.loading = false;

        if (this.selectedCity.radioTowerCoordinates) {
          this.addRadioTower(this.selectedCity.radioTowerCoordinates);
        }

        if (this.selectedCity.name === 'Tartu') {
          this.addCybexerLogo();
          this.addElecticityGrid();
        }

        if (this.scene.getAnimationGroupByName(this.selectedCity.drone.affectedAnimationName)) {
          this.scene.getAnimationGroupByName(this.selectedCity.drone.affectedAnimationName).stop();
        }
      }
    );

    this.scene.activeCameras = [this.camera, this.guiCamera, this.mapCamera];
  }

  teamChange() {
    if (this.selectedCity.drone) {
      if (!this.drone) {
        this.addDrone(this.selectedCity.drone.coordinates);
      }
    }
  }

  loadCityScene(
    shadowGenerator: ShadowGenerator,
    scenePath: string,
    sceneFile: string,
    callback?: () => void
  ) {
    this.loading = true;
    SceneLoader.Append(scenePath, sceneFile, this.scene, (loadedScene) => {
      const housing = loadedScene.getTransformNodeByName('Housing');
      const housing_ext = loadedScene.getTransformNodeByName('ext_houses');
      const ground = loadedScene.getTransformNodeByName('Ground');
      const water = loadedScene.getTransformNodeByName('River');
      const asphalt = loadedScene.getTransformNodeByName('Roads');
      const greens = loadedScene.getTransformNodeByName('Greens');
      const matglow = loadedScene.getMaterialByName('vilkur_test');
      (matglow as PBRMaterial).emissiveIntensity = 10;
      (matglow as PBRMaterial).emissiveColor = new Color3(0.0, 0.0, 10);

      if (housing instanceof TransformNode) {
        housing.getChildMeshes().forEach((mesh) => {
          mesh.receiveShadows = true;
          mesh.material = this.materialDefault;
          shadowGenerator.addShadowCaster(mesh);
        });
      }
      if (housing_ext instanceof TransformNode) {
        housing_ext.getChildMeshes().forEach((mesh) => {
          mesh.receiveShadows = true;
          mesh.material = this.materialInactive;
          mesh.material.fogEnabled = true;
          mesh.isPickable = false;
        });
      }
      if (ground instanceof TransformNode) {
        ground.getChildMeshes().forEach((mesh) => {
          (mesh.material as PBRMaterial).albedoColor = new Color3(0.09, 0.14, 0.2);
          (mesh.material as PBRMaterial).metallic = 0;
          (mesh.material as PBRMaterial).roughness = 100;
          (mesh.material as PBRMaterial).reflectivityColor = new Color3(0, 0, 0);
          (mesh.material as PBRMaterial).reflectionColor = new Color3(0, 0, 0);
          mesh.useVertexColors = false;
          mesh.material.fogEnabled = true;
          mesh.isPickable = false;
          mesh.receiveShadows = true;
        });
      }
      if (water instanceof TransformNode) {
        water.getChildMeshes().forEach((mesh) => {
          (mesh.material as PBRMaterial).albedoColor = new Color3(0.17, 0.22, 0.27);
          mesh.useVertexColors = false;
          mesh.receiveShadows = true;
          mesh.material.fogEnabled = true;
          mesh.isPickable = false;
        });
      }

      if (asphalt instanceof TransformNode) {
        asphalt.getChildMeshes().forEach((mesh) => {
          (mesh.material as PBRMaterial).metallic = 0;
          (mesh.material as PBRMaterial).roughness = 100;
          (mesh.material as PBRMaterial).reflectivityColor = new Color3(0, 0, 0);
          (mesh.material as PBRMaterial).reflectionColor = new Color3(0, 0, 0);
          mesh.material.fogEnabled = true;
          mesh.isPickable = false;
          mesh.useVertexColors = false;
        });
      }

      if (greens instanceof TransformNode) {
        greens.getChildMeshes().forEach((mesh) => {
          (mesh.material as PBRMaterial).metallic = 0;
          (mesh.material as PBRMaterial).roughness = 100;
          (mesh.material as PBRMaterial).reflectivityColor = new Color3(0, 0, 0);
          (mesh.material as PBRMaterial).reflectionColor = new Color3(0, 0, 0);
          mesh.material.fogEnabled = true;
          mesh.isPickable = false;
          mesh.useVertexColors = false;
        });
      }

      if (typeof callback === 'function') {
        callback();
      }
    });
  }

  closeInfoPanel() {
    this.resetSelectedBulding();
    this.isInfoPanelOpen.set(false);
  }

  private resetSelectedBulding() {
    if (this.selectedMesh) {
      this.selectedMesh.renderOutline = false;
      this.selectedMesh.edgesColor = new Color4(0.8, 0.8, 0.8, 0.2);
      this.selectedMesh.material = this.meshOriginalMaterials.get(this.selectedMesh) || null;
      this.selectedBuildingDetails = null;
      this.selectedMesh = null;
    }
  }

  toggleMap() {
    this.isMapOpen = !this.isMapOpen;
    if (this.isMapOpen) {
      this.scene.activeCameras = [this.camera, this.guiCamera, this.mapCamera];
      this.mapBackgroundBox.isVisible = true;
    } else {
      this.scene.activeCameras = [this.camera, this.guiCamera];
      this.mapBackgroundBox.isVisible = false;
    }
  }

  ngOnDestroy() {
    this.engine?.dispose();
  }
}
