import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { Store } from '@ngxs/store';
import { NotificationsService } from '@cybexer/ngx-commons';
import { QueuePosition } from 'app/models/gamenet/queue-position.model';
import { BehaviorSubject, mergeMap, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  Exercise,
  ExerciseDuration,
  FileInfo,
  NetworkSegment,
  ScoreType,
  UserListItem,
} from '../../models';
import {
  ExerciseOverview,
  ExerciseStatusInfo,
  ExerciseType,
  ExerciseWithStatus,
  StartexAndEndex,
  SystemEvent,
  SystemEventType,
  TeamSettings,
} from '../../models/gamenet/exercise.model';
import { QueueStatistics } from '../../models/gamenet/queue-stats.model';
import { TeamAllocation, TeamAllocationSave } from '../../models/gamenet/team-allocation.model';
import { DateUtil, ResetFilter, SetFilterProperty } from '../../shared';
import { EventSourceUtil } from '../../shared/eventsource.util';
import { PreferenceService } from '../shared/preference.service';

export enum SchemaType {
  EXERCISE = 'EXERCISE',
  EXERCISE_DEFINITION = 'EXERCISE_DEFINITION',
}

@Injectable()
export class ExerciseService {
  activeExercise: BehaviorSubject<Exercise>;
  exerciseListChanged: EventEmitter<Exercise> = new EventEmitter();
  private blueTeamIdsByExercise: { [exerciseId: string]: string } = {};
  private blueTeamNames: { [teamValue: string]: string } = {};

  constructor(
    private preferenceService: PreferenceService,
    private http: HttpClient,
    private notificationsService: NotificationsService,
    private zone: NgZone,
    private dateUtil: DateUtil,
    private store: Store
  ) {
    this.activeExercise = new BehaviorSubject<Exercise>(null);
  }

  getExerciseOverviews(): Observable<ExerciseOverview[]> {
    return this.http.get<ExerciseOverview[]>(`api/exercise`).pipe(
      map((dataArray) => {
        return dataArray.map((data) => new ExerciseOverview(data));
      })
    );
  }

  selectExercise(exerciseId: string, silent: boolean = false): void {
    this.getExercise(exerciseId).subscribe((exercise) => {
      this.setActiveExercise(exercise);
      this.preferenceService.updatePreferences({ defaultExerciseId: exercise.id }).subscribe();
      if (!silent) {
        this.notificationsService.success('Module has been selected');
      }
    });
  }

  getCompatibleExercises(exerciseId: string): Observable<string[]> {
    return this.http.get<string[]>(`api/exercise/${exerciseId}/compatible`);
  }

  setActiveExercise(exercise: Exercise) {
    this.updateBlueTeamNames(exercise);
    this.store.dispatch(new ResetFilter());
    if (exercise) {
      this.getExerciseDuration(exercise.id)
        .pipe(map((exerciseDuration) => this.dateUtil.getStartingTimeOrDefault(exerciseDuration)))
        .subscribe((startDate) => {
          this.store.dispatch(new SetFilterProperty('startDate', startDate));
        });
    }
    this.activeExercise.next(exercise);
  }

  private updateBlueTeamNames(exercise: Exercise) {
    this.blueTeamNames = {};

    if (exercise != null) {
      exercise.blueTeams.forEach((team) => {
        this.blueTeamNames[team.id] = team.customName;
        this.blueTeamNames[team.name] = team.customName;
      });
    }
  }

  getCurrentExerciseBlueTeamNames() {
    return this.blueTeamNames;
  }

  getActiveExercise(): Observable<Exercise> {
    if (this.activeExercise.value) {
      return this.activeExercise.asObservable();
    }
    return this.preferenceService
      .getPreferences()
      .pipe(
        mergeMap((pref) =>
          pref.defaultExerciseId ? this.getExercise(pref.defaultExerciseId) : of(null)
        )
      );
  }

  getExercise(id: string): Observable<Exercise> {
    return this.http.get<Exercise>(`api/exercise/${id}`).pipe(map((data) => new Exercise(data)));
  }

  getExerciseWithStatus(id: string): Observable<ExerciseWithStatus> {
    return this.http
      .get<Exercise>(`api/exercise/${id}/with-status`)
      .pipe(map((data) => new ExerciseWithStatus(data)));
  }

  getExerciseStatus(id: string): Observable<ExerciseStatusInfo> {
    return this.http
      .get<ExerciseStatusInfo>(`api/exercise/${id}/status`)
      .pipe(map((data) => new ExerciseStatusInfo(data)));
  }

  fetchAndSetExerciseActive(id: string): Observable<Exercise> {
    return this.getExercise(id).pipe(tap((e) => this.setActiveExercise(e)));
  }

  createExercise(exerciseJSON: string): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post(`api/exercise/`, exerciseJSON, httpOptions).pipe(map(() => true));
  }

  replaceExercise(exerciseId, exerciseJSON: string): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http
      .post(`api/exercise/${exerciseId}`, exerciseJSON, httpOptions)
      .pipe(map(() => true));
  }

  updateExercise(exerciseJSON: string): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.put(`api/exercise/`, exerciseJSON, httpOptions).pipe(map(() => true));
  }

  deleteExercise(exerciseId): Observable<boolean> {
    return this.http.delete(`api/exercise/${exerciseId}`).pipe(map(() => true));
  }

  resetExercise(exerciseId: string): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http
      .post(`api/exercise/${exerciseId}/reset`, null, httpOptions)
      .pipe(map(() => true));
  }

  resetExerciseTeamData(exerciseId: string, teamId: string): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http
      .post(`api/exercise/${exerciseId}/teams/${teamId}/reset`, null, httpOptions)
      .pipe(map(() => true));
  }

  getObjectiveCategories(): Observable<string[]> {
    return this.http.get<string[]>(`api/exercise/objective/category`);
  }

  getExerciseDuration(id: string): Observable<ExerciseDuration> {
    return this.http.get<Array<StartexAndEndex>>(`api/system-event/exercise/${id}/duration`).pipe(
      map((data) => {
        return data.reduce(
          (acc, startexEndexPair) => {
            acc.startexEvents.push(startexEndexPair.startex);
            if (startexEndexPair.endex) {
              acc.endexEvents.push(startexEndexPair.endex);
            }
            return acc;
          },
          new ExerciseDuration({ startexEvents: [], endexEvents: [] })
        );
      })
    );
  }

  createSystemEvent(exerciseId: string, event: SystemEvent): Observable<boolean> {
    return this.http.post(`api/system-event/exercise/${exerciseId}`, event).pipe(map(() => true));
  }

  reloadScripts(exerciseId: string): Observable<boolean> {
    return this.http.post(`api/exercise/${exerciseId}/reload-scripts`, null).pipe(map(() => true));
  }

  updateNetworkSegment(exerciseId: string, networkSegment: NetworkSegment): Observable<boolean> {
    return this.http
      .put(`api/exercise/${exerciseId}/network-segments/${networkSegment.id}`, {
        name: networkSegment.name,
        color: networkSegment.color,
      })
      .pipe(map(() => true));
  }

  getUserBlueTeam(exerciseId: string): Observable<string> {
    if (this.blueTeamIdsByExercise[exerciseId]) {
      return of(this.blueTeamIdsByExercise[exerciseId]);
    }
    return this.http
      .get(`api/exercise/${exerciseId}/my-blue-team`, {
        responseType: 'text',
      })
      .pipe(
        tap((blueTeamId) => {
          if (blueTeamId) {
            this.blueTeamIdsByExercise[exerciseId] = blueTeamId;
          }
        })
      );
  }

  listenForSystemEvents(exerciseId: string): Observable<SystemEventType> {
    return EventSourceUtil.create(`api/system-event/exercise/${exerciseId}/sse`, this.zone);
  }

  clearBlueTeamCache() {
    this.blueTeamIdsByExercise = {};
  }

  getExerciseScoreTypes(id: string): Observable<ScoreType[]> {
    return this.http.get<ScoreType[]>(`api/exercise/${id}/score-types`);
  }

  getTeamSettings(exerciseId: string, teamId: string): Observable<TeamSettings> {
    return this.http.get<any>(`api/exercise/${exerciseId}/teams/${teamId}/settings`);
  }

  getTeamAvatar(exerciseId: string, teamId: string): Observable<string> {
    return this.http
      .get(`api/exercise/${exerciseId}/teams/${teamId}/avatar`, { responseType: 'blob' })
      .pipe(map((blob) => URL.createObjectURL(blob)));
  }

  saveTeamSettings(
    exerciseId: string,
    teamId: string,
    teamName: string,
    avatarFile?: File
  ): Observable<boolean> {
    const formData = new FormData();
    if (avatarFile) {
      formData.append('file', avatarFile);
    }
    formData.append('teamName', teamName);
    return this.http
      .post<any>(`api/exercise/${exerciseId}/teams/${teamId}/settings`, formData)
      .pipe(map(() => true));
  }

  listenForTeamSettingsUpdateEvents(exerciseId: string): Observable<string> {
    return EventSourceUtil.create(`api/exercise/${exerciseId}/team-settings/subscribe`, this.zone);
  }

  getExerciseBlueTeamUsers(exerciseId: string, teamId: string): Observable<UserListItem[]> {
    return this.http
      .get<UserListItem[]>(`api/exercise/${exerciseId}/teams/${teamId}/users`)
      .pipe(map((data) => data.map((user) => new UserListItem(user))));
  }

  getQueuePosition(exerciseId: string): Observable<QueuePosition> {
    return this.http.get<QueuePosition>(`api/queue/${exerciseId}`);
  }

  getQueueStats(exerciseId: string): Observable<QueueStatistics> {
    return this.http.get<QueueStatistics>(`api/queue/${exerciseId}/stats`);
  }

  getTeamAllocation(exerciseId: string, roleType: string = 'PRE_BLUE'): Observable<TeamAllocation> {
    const params = new HttpParams().set('roleType', roleType);
    return this.http
      .get<TeamAllocation>(`api/queue/${exerciseId}/team-allocation`, { params })
      .pipe(map((data) => new TeamAllocation(data)));
  }

  putTeamAllocation(
    exerciseId: string,
    teamAllocationSave: TeamAllocationSave
  ): Observable<boolean> {
    return this.http
      .put(`api/queue/${exerciseId}/team-allocation`, teamAllocationSave)
      .pipe(map(() => true));
  }

  uploadImage(exerciseId: string, image: File): Observable<FileInfo> {
    const formData = new FormData();
    formData.append('file', image);

    return this.http
      .post(`api/exercise/${exerciseId}/image`, formData)
      .pipe(map((data) => new FileInfo(data)));
  }

  getExerciseImage(exerciseId: string): Observable<string> {
    return this.http
      .get(`api/exercise/${exerciseId}/image`, { responseType: 'blob' })
      .pipe(map((blob) => URL.createObjectURL(blob)));
  }

  deleteExerciseImage(exerciseId: string): Observable<boolean> {
    return this.http.delete(`api/exercise/${exerciseId}/image`).pipe(map(() => true));
  }

  getExerciseJsonSchema(
    exerciseType: ExerciseType,
    isExerciseDefinition: boolean
  ): Observable<string> {
    const schemaType = isExerciseDefinition ? SchemaType.EXERCISE_DEFINITION : SchemaType.EXERCISE;
    let params = new HttpParams();
    if (exerciseType) {
      params = params.set('exerciseType', exerciseType);
    }

    return this.http.get<string>(`api/exercise/json-schema/${schemaType}`, {
      params: params,
    });
  }
}
