<div class="error-page">
  <div class="glitch" [attr.data-text]="errorCode">{{ errorCode }}</div>
  <div class="glitch glitch-text" [attr.data-text]="'There is a glitch in the matrix'">
    There is a glitch in the matrix
  </div>
  <div class="click-container">
    Maybe <a (click)="navigateToLandingPage()">clicking here</a> helps
  </div>
  <div class="message">(or we're doing it for you eventually)</div>
</div>
